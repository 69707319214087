import React, { Suspense } from "react";
import { useEffect, useState, useRef, useReducer } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Article from "./Article";

import "../../css/Filter.css";
import { useSelector } from "react-redux";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function Filter() {
  const [publishDate, setPublishDate] = useState("");
  const [endPublishDate, setEndPublishDate] = useState("");
  const { t, i18n } = useTranslation(["Articles"]);
  const [searchList, setSearchList] = useState([]);
  const [search, setSearch] = useState(false);
  const [word, setWord] = useState("");
  const ref = useRef(null);
  const [searchArticle, setSearchArticle] = useState([]);
  const [open, setOpen] = React.useState(false);

  const userState = useSelector((state) => state.user);

  const handleDelete = (chipToDelete) => () => {
    setSearchList((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSpace = (e) => {
    if (e.keyCode === 13) {
      setSearchList((prev) => [...prev, word]);
      setWord("");
      ref.current.value = "";
    }
  };

  const simulateKeyPress = (keyCode) => {
    const event = new KeyboardEvent("keydown", {
      keyCode: keyCode,
      bubbles: true,
      cancelable: true,
    });
    const targetElement = document.getElementById("search");
    if (targetElement && targetElement.value) {
      targetElement.dispatchEvent(event);
    }
  };

  useEffect(() => {
    handleSearch();
    if (searchList.length > 0) {
      handleSearchByKeywords();
    } else if (publishDate || endPublishDate) {
      handleSearchByDate();
    }
    setSearch(false);
  }, [search]);

  const handleSearch = () => {
    simulateKeyPress(13);
  };

  const handleSearchByDate = async () => {
    var data = {
      username: userState.username,
      publicationDate: publishDate || null,
      endPublicationDate: endPublishDate || null,
    };

    fetch(`${process.env.REACT_APP_API_URL}/article/SearchArticleByDate`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then(async (data) => {
        setSearchArticle(data);
        setOpen(true);
      });
  };

  const handleSearchByKeywords = async () => {
    var data = {
      username: userState.username,
      keywords: searchList,
      publicationDate: publishDate || null,
      endPublicationDate: endPublishDate || null,
    };
    fetch(`${process.env.REACT_APP_API_URL}/article/SearchArticleByKeyWords`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then(async (data) => {
        if (data.length > 0) {
          setSearchArticle(data);
          setOpen(true);
        } else {
          setSearchArticle();
          setOpen(true);
        }
      });
  };

  const ModalResearchedArticle = () => {
    if (searchArticle) {
      return (
        <Suspense>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="MemoHebdo__Modal">
              {searchArticle.length == 100 ? t("PreciseSearch") : ""}
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {searchArticle.map(
                  (memo) =>
                    memo.textFr &&
                    memo.textFr.length > 0 && (
                      <>
                        <Article memo={memo} />
                      </>
                    )
                )}
              </Typography>
            </Box>
          </Modal>
        </Suspense>
      );
    } else {
      return (
        <Suspense>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="MemoHebdo__Modal">
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("NoResultsFound")}
              </Typography>
            </Box>
          </Modal>
        </Suspense>
      );
    }
  };

  return (
    <div className="Filter__main">
      <Stack spacing={2} direction="row" className="Filter__Search">
        <div className="Filter__date">
          <div className="Filter__date__datePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <label>{t("StartDate")}</label>
              <DateTimePicker
                defaultValue={dayjs(publishDate)}
                value={dayjs(publishDate)}
                onChange={(newValue) => setPublishDate(newValue)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <label>{t("EndDate")}</label>
              <DateTimePicker
                defaultValue={dayjs(endPublishDate)}
                value={dayjs(endPublishDate)}
                onChange={(newValue) => setEndPublishDate(newValue)}
              />
            </LocalizationProvider>
          </div>
        </div>

        <TextField
          margin="normal"
          name="search"
          label={t("Search")}
          type="text"
          id="search"
          autoComplete="current-password"
          onChange={(e) => setWord(e.currentTarget.value)}
          onKeyDown={(e) => handleSpace(e)}
          value={word}
          ref={ref}
        />
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {searchList ? (
            searchList.map((data) => {
              let icon;

              return (
                <ListItem key={data.key}>
                  <Chip
                    icon={icon}
                    label={data}
                    onDelete={handleDelete(data)}
                  />
                </ListItem>
              );
            })
          ) : (
            <></>
          )}
        </Paper>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#FFBC0F", color: "black" }}
          onClick={() => setSearch(true)}
        >
          {t("Search")}
        </Button>
      </Stack>
      {ModalResearchedArticle()}
    </div>
  );
}
