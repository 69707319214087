import React from "react";

function Frame({ url }) {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <iframe
        src={url}
        style={{ border: "none", height: "100%", width: "100%" }}
        title="Embedded Page"
      ></iframe>
    </div>
  );
}

export default Frame;
