import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, {
  modules,
  QuillToolbar2,
  modules2,
  formats,
} from "../Memo/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import "../../css/EditorSpecialMessage.css";
import { useSelector } from "react-redux";

export function EditorSpecialMessage() {
  const [textFr, settextFr] = React.useState(null);
  const [textEn, settextEn] = React.useState(null);
  const { t } = useTranslation(["Articles"]);
  const [publishDate, setPublishDate] = useState("");
  const [endPublishDate, setEndPublishDate] = useState("");
  const [canSave, setCanSave] = useState(true);

  const userState = useSelector((state) => state.user);

  const handleChangeFr = async (value) => {
    settextFr(value);
  };

  const handleChangeEn = (value) => {
    settextEn(value);
  };

  const handleSave = async () => {
    var messageData = {
      TextFR: textFr,
      TextEN: textEn,
      Author: userState.username,
      publishedDate: publishDate,
      endPublishedDate: endPublishDate,
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/specialMessage/SaveMessageCasserole`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(messageData),
      }
    )
      .then((results) => {
        return results.json();
      })
      .then(() => {});
  };

  const handleClearDate = () => {
    setPublishDate("");
    setEndPublishDate("");
  };

  const getSpecialMessage = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/specialMessage/GetMessage`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        settextEn(data.textEn);
        settextFr(data.textFr);
        setEndPublishDate(data.endPublicationDate);
        setPublishDate(data.publicationDate);
      });
  };

  useEffect(() => {
    getSpecialMessage();
  }, []);

  useEffect(() => {
    if (endPublishDate && publishDate) {
      setCanSave(false);
    }
  }, [publishDate, endPublishDate]);

  return (
    <div>
      <div className="EditorSpecialMessage__date__datePicker">
        <div className="EditorSpecialMessage__date__datePicker__publicationDate">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <label>{t("PublicationDate")}</label>
            <DateTimePicker
              defaultValue={dayjs(publishDate)}
              value={dayjs(publishDate)}
              onChange={(newValue) => setPublishDate(newValue)}
            />
          </LocalizationProvider>
        </div>
        <div className="EditorSpecialMessage__date__datePicker__endPublishedDate">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <label>{t("EndPublishedDate")}</label>
            <DateTimePicker
              defaultValue={dayjs(endPublishDate)}
              value={dayjs(endPublishDate)}
              onChange={(newValue) => setEndPublishDate(newValue)}
            />
          </LocalizationProvider>
        </div>
        <Button variant="contained" onClick={handleClearDate}>
          {t("ClearDate")}
        </Button>
      </div>
      <div className="EditorSpecialMessage__EditorFr">
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={textFr}
          onChange={handleChangeFr}
          placeholder={"message en francais..."}
          modules={modules}
          formats={formats}
        />
      </div>
      <div className="EditorSpecialMessage__EditorEn">
        <QuillToolbar2 />
        <ReactQuill
          theme="snow"
          value={textEn}
          onChange={handleChangeEn}
          placeholder={"message in english..."}
          formats={formats}
          modules={modules2}
        />
      </div>
      <Button onClick={handleSave} variant="contained" disabled={canSave}>
        {t("Save")}
      </Button>
    </div>
  );
}

export default EditorSpecialMessage;
