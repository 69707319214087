import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

function GroupManagement() {
  const [checked, setChecked] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesByAccessRight, setEmployeesByAccessRight] = useState([]);

  function not(a, b) {
    return a.filter((value) => !b.includes(value));
  }

  function intersection(a, b) {
    return a.filter((value) => b.includes(value));
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  // Calculate items that are checked in both lists
  const availableChecked = intersection(
    checked,
    employees.map((item) => item.username)
  );
  const selectedChecked = intersection(
    checked,
    employeesByAccessRight.map((item) => item.username)
  );

  const numberOfChecked = (items) =>
    intersection(
      checked,
      items.map((item) => item.username)
    ).length;

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = (items) => () => {
    const itemUsernames = items.map((item) => item.username);
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, itemUsernames));
    } else {
      setChecked(union(checked, itemUsernames));
    }
  };

  const handleCheckedRight = () => {
    const newlySelected = employees.filter((user) =>
      checked.includes(user.username)
    );
    setEmployeesByAccessRight((prev) => union(prev, newlySelected));
    setEmployees((prev) => not(prev, newlySelected));
    setChecked((prev) =>
      not(
        prev,
        newlySelected.map((user) => user.username)
      )
    );
  };

  const handleCheckedLeft = () => {
    const newlyAvailable = employeesByAccessRight.filter((user) =>
      checked.includes(user.username)
    );
    setEmployees((prev) => union(prev, newlyAvailable));
    setEmployeesByAccessRight((prev) => not(prev, newlyAvailable));
    setChecked((prev) =>
      not(
        prev,
        newlyAvailable.map((user) => user.username)
      )
    );
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 400,
          height: 320,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.username}-label`;

          return (
            <ListItemButton
              key={value.username}
              role="listitem"
              onClick={handleToggle(value.username)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(value.username)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.username} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );
  return (
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ m: 5 }}
      >
        <Grid item>{customList("All Employees", employees)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={availableChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={selectedChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {customList("Employees with Selected Right", employeesByAccessRight)}
        </Grid>
      </Grid>
    </div>
  );
}

export default GroupManagement;
