import React from "react";
import Frame from "../Common/Frame";

const url = "https://forms.office.com/r/mB4mbC1xwU";

function OfficeForm() {
  return (
    <div className="App">
      <Frame url={url} />
    </div>
  );
}

export default OfficeForm;
