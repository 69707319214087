import React from "react";
import Badge from "@mui/material/Badge";

import "../../css/Category.css";

export function Category(props) {
  return (
    <div className="Category__main">
      {props.blackAndWhite ? (
        <img
          src={props.name}
          onClick={props.onclick}
          value={props.idCategory}
          style={{ filter: "grayscale(100%)" }}
        />
      ) : (
        <img
          src={props.name}
          onClick={props.onclick}
          value={props.idCategory}
        />
      )}
      <Badge
        color="primary"
        overlap="circular"
        sx={{
          "& .MuiBadge-badge": {
            fontSize: 12,
            height: 25,
            minWidth: 25,
            backgroundColor: "red",
          },
        }}
        badgeContent={props.unread}
      ></Badge>
    </div>
  );
}
