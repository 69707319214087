import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Login from "../Login";
import { useSelector } from "react-redux";

function QuestionsForm(props) {
  const [text, setText] = useState();
  const [listeDeChoix, setListeDeChoix] = useState();
  const [choix, setChoix] = useState();
  const { t, i18n } = useTranslation(["Articles"], ["Common"]);
  const [commentaireRequired, setCommentaireRequired] = useState();

  const userState = useSelector((state) => state.user);

  const userLang = useSelector((state) => state.user.lang);

  useEffect(() => {
    setChoix("");
    setText("");
    setCommentaireRequired(false);
    if (props.questions && props.questions.idGroupeDeChoix !== 2) {
      GetListeDeChoix();
    }
    SetDropDownData();
  }, [props]);

  useEffect(() => {
    if (text && listeDeChoix) {
      handleSave();
    }
  }, [choix, text]);

  const SetDropDownData = () => {
    if (props.questions && props.answers[0]) {
      setChoix(
        props.answers.filter((a) => a.idQuestion === props.questions.id)[0]
          ? props.answers.filter((a) => a.idQuestion === props.questions.id)[0]
              .commentaire
          : ""
      );

      if (listeDeChoix) {
        listeDeChoix.forEach((l) => {
          props.answers.forEach((ans) => {
            if (ans.idListeDeChoix === l.id) setChoix(l.textEn);
          });
          if (l.commentaireRequis === true) {
            props.answers.forEach((ans) => {
              if (ans.idListeDeChoix === l.id) {
                setCommentaireRequired(true);
                if (userLang === "EN") {
                  setChoix(l.textEn);
                } else {
                  setChoix(l.textFr);
                }
              }
            });
          }
        });
      }

      setText(
        props.answers.filter((a) => a.idQuestion === props.questions.id)[0]
          ? props.answers.filter((a) => a.idQuestion === props.questions.id)[0]
              .commentaire
          : ""
      );
    }
  };

  const GetListeDeChoix = async () => {
    if (props.questions) {
      fetch(
        `${process.env.REACT_APP_API_URL}/Report/GetListeDeChoix/${props.questions.idGroupeDeChoix}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setListeDeChoix(data);
        });
    }
  };

  const handleSave = async () => {
    let idListeDeChoix = 2;
    if (
      props.questions.idGroupeDeChoix &&
      props.questions.idGroupeDeChoix !== 2
    ) {
      const filteredChoix = listeDeChoix
        .filter((c) => c.idGroupeDeChoix === props.questions.idGroupeDeChoix)
        .filter(
          (ans) =>
            ans.textFr === text ||
            ans.textFr === choix ||
            ans.textEn === text ||
            ans.textEn === choix
        );
      if (filteredChoix.length > 0) {
        idListeDeChoix = filteredChoix[0].id;
      }
    }

    const answerToSave = {
      IdQuestion: props.questions.id,
      IdActivite: props.idActivite,
      Reponse: text,
      IdListeDeChoix: idListeDeChoix,
      Username: userState.username,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Report/SaveAnswer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(answerToSave),
        }
      );

      if (!response.ok) {
        alert(`Failed to save answer.`);
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      // Handle the response data as needed
    } catch (error) {
      alert(`Failed to save answer. ${error.message}`);
      console.error("Error saving answer:", error);
    }
  };

  const handleChangeAnswer = (event, newValue) => {
    // newValue will contain the selected option text
    setChoix(newValue || "");
    setCommentaireRequired(false);

    if (newValue) {
      const selectedOption = listeDeChoix.find(
        (l) => l.textFr === newValue || l.textEn === newValue
      );

      if (selectedOption) {
        setCommentaireRequired(selectedOption.commentaireRequis);

        if (selectedOption.commentaireRequis === false) {
          setText(newValue);
          handleSave(newValue);
        }
      }
    }
  };

  return (
    <>
      {props.questions &&
        (props.withoutChild ? (
          userLang === "EN" ? (
            <h4>{props.questions.textEn}</h4>
          ) : (
            <h4>{props.questions.textFr}</h4>
          )
        ) : userLang === "EN" ? (
          props.questions.textEn
        ) : (
          props.questions.textFr
        ))}

      {props.questions && props.questions.idGroupeDeChoix === 2 ? (
        <TextField
          key={props.questions.id}
          id="outlined-multiline-flexible"
          multiline
          minRows={2}
          disabled={props.answersEnable}
          onKeyUp={(text) => handleSave(text)}
          value={text || ""}
          onChange={(e) => setText(e.currentTarget.value)}
        />
      ) : (
        <Autocomplete
          key={props.questions.id}
          id={t("answer")}
          options={
            listeDeChoix
              ? listeDeChoix.map((choix) =>
                  userLang === "EN" ? choix.textEn : choix.textFr
                )
              : []
          }
          onChange={handleChangeAnswer}
          value={choix || ""}
          disabled={props.answersEnable}
          renderInput={(params) => (
            <TextField {...params} label={t("Answer")} />
          )}
        />
      )}
      {commentaireRequired && (
        <TextField
          id="outlined-multiline-flexible"
          multiline
          value={text || ""}
          onChange={(e) => setText(e.currentTarget.value)}
          onKeyUp={(text) => handleSave(text)}
        />
      )}
    </>
  );
}

export default QuestionsForm;
