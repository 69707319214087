import React, { useState, useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Layout";
import Login from "./components/Login";
import Cookies from "js-cookie";
import { AccessRight } from "./utils/AccessRight";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { Provider, useSelector } from "react-redux";
import CacheBuster from "react-cache-buster";
const packageJson = require("../package.json");
const version = packageJson.version;

export default function App() {
  const displayName = App.name;
  const [employe, setEmploye] = useState();
  const [canEditArticle, setCanEditArticle] = useState(false);
  const [canWriteSpecailMessage, setCanWriteSpecailMessage] = useState(false);
  const [canModifyAPI, setCanModifyAPI] = useState(false);
  const isProduction = process.env.NODE_ENV === "production";

  const userState = useSelector((state) => state.user);

  const getEmployeAccesRight = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/employee/GetEmployeAccessRight/${userState.username}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Try to parse the response as JSON
      let data;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        data = await response.json();
      } else {
        data = await response.text(); // Handle plain text response
      }

      if (
        typeof data === "string" &&
        data === "No access rights found for the specified username."
      ) {
        // Handle the specific case where no access rights are found
        console.warn(data);
        return;
      }

      // Assuming data is an array of employe objects
      setEmploye(data);

      if (data.some((e) => e.noAcessRight === AccessRight.MEMOHEBDO_EDITEUR)) {
        setCanEditArticle(true);
      }

      if (data.some((e) => e.noAcessRight === AccessRight.CASSEROLES_ADMIN)) {
        setCanWriteSpecailMessage(true);
      }

      if (data.some((e) => e.noAcessRight === AccessRight.SYSTEM_ADMIN)) {
        setCanModifyAPI(true);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      // Optionally, you can show a notification to the user here
    }
  };

  const containsAccessRight = (valueToFind) => {
    console.log(employe.length);
    if (valueToFind !== undefined) {
      if (valueToFind.includes(null)) return true;
      return employe.some((e) => valueToFind.includes(e.noAcessRight));
    }

    //console.log(Object.values(AccessRight).includes(valueToFind));
    //return Object.values(AccessRight).includes(valueToFind);
  };

  useEffect(() => {
    if (Cookies.get("isAuthentificated")) {
      getEmployeAccesRight();
    }
  }, []);

  useEffect(() => {
    if (Cookies.get("isAuthentificated")) {
      getEmployeAccesRight();
    }
  }, [Cookies.get("isAuthentificated")]);

  const hasAccess = (route) => {
    if (employe) {
      return containsAccessRight(route.requiredRole);
    }
  };

  return (
    <Suspense>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CacheBuster
            currentVersion={version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            //loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
            metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
          >
            {!Cookies.get("isAuthentificated") ? (
              <Login />
            ) : (
              <>
                <Layout>
                  <Routes>
                    {AppRoutes.map((route, index) => {
                      if (hasAccess(route)) {
                        const { element, ...rest } = route;
                        return (
                          <Route key={index} {...rest} element={element} />
                        );
                      }
                    })}
                  </Routes>
                </Layout>
              </>
            )}
          </CacheBuster>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}
