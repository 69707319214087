import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Parser from "html-react-parser";
import Cookies from "js-cookie";
import Badge from "@mui/material/Badge";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { hash } from "../../utils/Hash";
import { hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";

import "../../css/Article.css";
import { useSelector } from "react-redux";

export default function Article(props) {
  const [attachments, setAttachments] = React.useState([]);
  const { t, i18n } = useTranslation(["Articles"]);

  const userState = useSelector((state) => state.user);
  const userLang = useSelector((state) => state.user.lang);

  useEffect(() => {
    getAttachment();
  }, []);

  const logReading = async () => {
    var data = {
      idObject: props.memo.id,
      object: "Article",
      username: userState.username,
      event: "Read",
      descriptionEvent: `Reading of article ${props.memo.id} `,
    };

    fetch(`${process.env.REACT_APP_API_URL}/Logger/ReadArticle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {});
  };

  const getAttachment = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/article/GetAttachments/${props.memo.id}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setAttachments(data);
      });
  };

  const ChangeColorTitle = (e) => {
    if (e.currentTarget.classList.contains("TitleAccordion")) {
      if (e.currentTarget.style.backgroundColor == "lightgrey") {
        e.currentTarget.style.backgroundColor = "white";
      } else {
        e.currentTarget.style.backgroundColor = "lightgrey";
      }
    }
    if (e.currentTarget.style.backgroundColor == "lightgrey") {
      logReading();
      if (props.unread) {
        props.reload(true);
      }
    }
  };

  const handleOpenFile = async (e) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/article/DownloadAttachment/${e.target.textContent}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = e.target.textContent;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      });
  };
  return (
    <div className="article__main">
      {userLang == "FR" && Parser(props.memo.titleFr) != "PAS DE TITRE" && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="TitleAccordion"
            onClick={ChangeColorTitle}
          >
            {props.unread && (
              <Badge
                color="primary"
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: 16,
                    backgroundColor: "red",
                  },
                }}
                className="article__main__Pastillebadge"
              ></Badge>
            )}
            {
              <Typography>
                <h5 key={props.id}>
                  {props.memo.titleFr.length > 0
                    ? Parser(props.memo.titleFr)
                    : Parser(props.memo.titleEn)}
                </h5>
              </Typography>
            }

            <Typography>
              <p key={props.id} className="article__main__detailArticle">
                {t("PublishedDate")} : {props.memo.publicationDate}
              </p>
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography>
              {props.memo.textFr.length > 0
                ? Parser(props.memo.textFr)
                : Parser(props.memo.textEn)}
            </Typography>
            {attachments.map((attachment) =>
              attachment.lang == userLang ? (
                <Link onClick={(e) => handleOpenFile(e)}>
                  <ListItemText primary={attachment.fileName} />
                </Link>
              ) : (
                <></>
              )
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {userLang == "EN" && Parser(props.memo.titleEn) != "NO TITLE" && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="TitleAccordion"
            onClick={ChangeColorTitle}
          >
            {props.unread && (
              <Badge
                color="primary"
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: 16,
                    backgroundColor: "red",
                  },
                }}
                className="article__main__Pastillebadge"
              ></Badge>
            )}
            {
              <Typography>
                <h5 key={props.id}>
                  {props.memo.titleEn.length > 0
                    ? Parser(props.memo.titleEn)
                    : Parser(props.memo.titleFr)}
                </h5>
              </Typography>
            }

            <Typography>
              <p key={props.id} className="article__main__detailArticle">
                {t("PublishedDate")} : {props.memo.publicationDate}
              </p>
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography>
              {props.memo.textEn.length > 0
                ? Parser(props.memo.textEn)
                : Parser(props.memo.textfr)}
            </Typography>
            {attachments.map((attachment) =>
              attachment.lang == userLang ? (
                <Link onClick={(e) => handleOpenFile(e)}>
                  <ListItemText primary={attachment.fileName} />
                </Link>
              ) : (
                <></>
              )
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
