import EditArticle from "./components/Memo/EditArticle";
import { MemoHebdo } from "./components/Memo/MemoHebdo";
import { SpecialMessage } from "./components/SpecialMessage/SpecialMessage";
import Profil from "./components/Profil";
import APIKey from "./components/APIKEY/APIKey";
import UserManagement from "./components/UserManagement/UserManagement";
import { AccessRight } from "./utils/AccessRight";
import CompanyManagement from "./components/CompaniesManagement/CompanyManagement";
import Emplacement from "./components/Emplacement/Emplacement";
import FormRepport from "./components/Repports/FormRepport";
import DashboardRepport from "./components/Repports/DashboardRepport";
import OfficeForm from "./components/Survey/OfficeForm";
import ComplainForm from "./components/Casserole/Plaintes/ComplainForm";
import AccessRightManagement from "./components/AcessRight/AccessRight";

const AppRoutes = [
  {
    index: true,
    element: <MemoHebdo />,
    requiredRole: [null],
  },
  {
    path: "/MemoHebdo",
    element: <MemoHebdo />,
    requiredRole: [null],
  },
  {
    path: "/EditArticle",
    element: <EditArticle />,
    requiredRole: [AccessRight.MEMOHEBDO_EDITEUR],
  },
  {
    path: "/Media",
    element: <MemoHebdo />,
    requiredRole: [null],
  },
  {
    path: "/Profil",
    element: <Profil />,
    requiredRole: [null],
  },
  {
    path: "/SpecialMessage",
    element: <SpecialMessage />,
    requiredRole: [AccessRight.CASSEROLES_ADMIN],
  },
  {
    path: "/APIKey",
    element: <APIKey />,
    requiredRole: [AccessRight.SYSTEM_ADMIN],
  },
  {
    path: "/UserManagement",
    element: <UserManagement />,
    requiredRole: [AccessRight.UserManagement],
  },
  {
    path: "/CompaniesManagement",
    element: <CompanyManagement />,
    requiredRole: [AccessRight.SYSTEM_ADMIN],
  },
  {
    path: "/Emplacement",
    element: <Emplacement />,
    requiredRole: [AccessRight.SYSTEM_ADMIN],
  },
  {
    path: "/Reports",
    element: <FormRepport />,
    requiredRole: [
      AccessRight.SYSTEM_ADMIN,
      AccessRight.REPORT_ADMIN,
      AccessRight.REPORT_EDDITOR,
    ],
  },
  {
    path: "/Dashboard",
    element: <DashboardRepport />,
    requiredRole: [
      AccessRight.SYSTEM_ADMIN,
      AccessRight.REPORT_ADMIN,
      AccessRight.REPORT_EDDITOR,
    ],
  },
  {
    path: "/CompetitionSurvey",
    element: <OfficeForm />,
    requiredRole: [null],
  },
  {
    path: "/Complaint",
    element: <ComplainForm />,
    requiredRole: [AccessRight.SYSTEM_ADMIN, AccessRight.CASSEROLES_ADMIN],
  },
  {
    path: "/AccessRight",
    element: <AccessRightManagement />,
    requiredRole: [AccessRight.SYSTEM_ADMIN],
  },
];

export default AppRoutes;
