import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Cookies from "js-cookie";
import "../../css/APIKey.css";
import Login from "../Login";
import { useSelector } from "react-redux";

function APIKey() {
  const { t, i18n } = useTranslation(["Articles"]);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [currentNoEmplacement, setCurrentNoEmplacement] = useState();
  const [currentNoCompagnie, setNoCurrentCompany] = useState();
  const [dataAPI, setDataAPI] = useState();
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const userState = useSelector((state) => state.user);

  const GetRestaurants = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetRestaurants`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRestaurants(data);
      });
  };

  const GetCompagnies = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/company/GetCompaniesByEmplacement/${currentNoEmplacement}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setCompanies(data);
      });
  };

  const GetIdKeyAPI = async (noCompany) => {
    fetch(`${process.env.REACT_APP_API_URL}/APIKey/GetIdKeyAPI/${noCompany}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setDataAPI(data);
      });
  };

  const handleChangeRestaurant = (event) => {
    setRestaurant(event.target.value);
    setCurrentNoEmplacement(
      restaurants.filter((r) => r.nomEmplacement == event.target.value)[0]
        .noEmplacement
    );
  };

  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
    setNoCurrentCompany(
      companies.filter((c) => c.nom == event.target.value)[0].noCompagnie
    );
    GetIdKeyAPI(
      companies.filter((c) => c.nom == event.target.value)[0].noCompagnie
    );
  };

  useEffect(() => {
    GetRestaurants();
  }, [restaurants]);

  useEffect(() => {
    GetCompagnies();
  }, [currentNoEmplacement]);

  useEffect(() => {
    const newData = createData();
    setRows({ ...rows, newData });
  }, [dataAPI]);

  useEffect(() => {
    const newData = createData();
    setRows(newData);
  }, [rows]);

  const createData = () => {
    if (dataAPI) {
      let idUser = dataAPI.idUser;
      let generatedDate = dataAPI.generatedDate;

      return [{ idUser, generatedDate }];
    }
  };

  const handleGenerateKey = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/APIKey/GetNewAPIKey/${currentNoCompagnie}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setAlert(true);
        setAlertContent(data.api_keys);
      });
  };

  const createNewKey = async () => {};

  return (
    <>
      {!Cookies.get("isAuthentificated") ? (
        <Login />
      ) : (
        <div>
          <Box sx={{ minWidth: 120 }}>
            <div className="APIKEY__filter">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("restaurant")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={restaurant ?? " "}
                  label={t("restaurant")}
                  onChange={(e) => handleChangeRestaurant(e)}
                >
                  {restaurants.length > 0 &&
                    restaurants.map((resto) => (
                      <MenuItem
                        id={resto.noEmplacement}
                        value={resto.nomEmplacement}
                      >
                        {resto.nomEmplacement}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("company")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={company ?? " "}
                  label={t("Company")}
                  onChange={handleChangeCompany}
                >
                  {companies.length > 0 &&
                    companies.map((compagnie) => (
                      <MenuItem
                        id={compagnie.noCompagnie}
                        value={compagnie.nom}
                      >
                        {compagnie.nom +
                          " | " +
                          compagnie.noAccpac +
                          " | " +
                          compagnie.dateOuverture +
                          " | " +
                          compagnie.dateFermeture}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </Box>
          <div className="APIKey__DataTable">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID Key</TableCell>
                    <TableCell align="right">Generated date</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length > 0 &&
                    rows.map((row) => (
                      <TableRow
                        key={row.idUser}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.idUser}
                        </TableCell>
                        <TableCell align="right">{row.generatedDate}</TableCell>
                        <TableCell align="right">
                          {
                            <Button onClick={handleGenerateKey}>
                              Generate Key
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {alert ? (
              <Alert
                severity="success"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(alertContent);
                    }}
                  >
                    Copy
                  </Button>
                }
              >
                new key generated successfully ! key : {alertContent}
              </Alert>
            ) : (
              ""
            )}
          </div>
        </div>
      )}{" "}
    </>
  );
}

export default APIKey;
