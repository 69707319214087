import React, { useEffect, useState } from "react";
import Article from "./Article";
import "../../css/MemoHebdo.css";
import { Filter } from "./Filter";
import { Category } from "./Category";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";

/*import imageFinanceV2 from "../../resources/logoDepartmentV2/ICONE_extranet_financeLegal.png";
import imageCasseroleV2 from "../../resources/logoDepartmentV2/ICONE_extranet_Casserole.png";
import imageInformatiqueV2 from "../../resources/logoDepartmentV2/ICONE_extranet_IT.png";
import imageRHV2 from "../../resources/logoDepartmentV2/ICONE_extranet_Resshumaine_divers.png";
import imageMarketingV2 from "../../resources/logoDepartmentV2/ICONE_extranet_Marketing.png";
import imageDeveloppementV2 from "../../resources/logoDepartmentV2/ICONE_extranet_Developpement.png";
import imageRDV2 from "../../resources/logoDepartmentV2/ICONE_extranet_RD_Achat.png";
import imageFormationV2 from "../../resources/logoDepartmentV2/ICONE_extranet_ops_Formation.png";*/

import imageMarketing from "../../resources/logoCategory/ICONE_extranet_Marketing.jpg";
import imageCasserole from "../../resources/logoCategory/ICONE_extranet_Casseroles.jpg";

import imageFinanceEN from "../../resources/logoCategory/ICONE_extranet_Finance_legal_EN.jpg";
import imageInformatiqueEN from "../../resources/logoCategory/ICONE_extranet_OperationSystem_IT_EN.jpg";
import imageRHEN from "../../resources/logoCategory/ICONE_extranet_humanresources_Misc_EN.jpg";
import imageDeveloppementEN from "../../resources/logoCategory/ICONE_extranet_Development_EN.jpg";
import imageRDEN from "../../resources/logoCategory/ICONE_extranet_RD_Procurement_EN.jpg";
import imageFormationEN from "../../resources/logoCategory/ICONE_extranet_Operation_training_EN.jpg";

import imageFinanceFR from "../../resources/logoCategory/ICONE_extranet_Finance_Legal.jpg";
import imageInformatiqueFR from "../../resources/logoCategory/ICONE_extranet_Systeme Explotation_TI_FR.jpg";
import imageRHFR from "../../resources/logoCategory/ICONE_extranet_RessHumaine_FR.jpg";
import imageDeveloppementFR from "../../resources/logoCategory/ICONE_extranet_developpement_FR.jpg";
import imageRDFR from "../../resources/logoCategory/ICONE_extranet_RD_Achat_FR.jpg";
import imageFormationFR from "../../resources/logoCategory/ICONE_extranet_Operation_formation_FR.jpg";
import { useSelector } from "react-redux";
import Login from "../Login";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function MemoHebdo() {
  const [memos, setMemo] = useState([]);
  const [currentCategory, setCurrentCategory] = useState();
  const [open, setOpen] = React.useState(false);
  const [unreadArticle, setUnreadArticle] = React.useState([]);

  const [unreadArticleRH, setUnreadArticleRH] = useState(0);
  const [unreadArticleRD, setUnreadArticleRD] = useState(0);
  const [unreadArticleCasserole, setUnreadArticleCasserole] = useState(0);
  const [unreadArticleFinance, setUnreadArticleFinance] = useState(0);
  const [unreadArticleFormation, setUnreadArticleFormation] = useState(0);
  const [unreadArticleInformatique, setUnreadArticleInformatique] = useState(0);
  const [unreadArticleDeveloppement, setUnreadArticleDeveloppement] =
    useState(0);
  const [unreadArticleMarketing, setUnreadArticleMarketing] = useState(0);

  const [reloadPublishArticle, setReloadPublishArticle] = useState(false);
  const [lang, setLang] = React.useState("en");
  const { t, i18n } = useTranslation(["Articles"]);

  const userState = useSelector((state) => state.user);
  const userLang = useSelector((state) => state.user.lang);

  const handleOpen = (e) => {
    setOpen(true);
    chooseCategory(e);
  };

  const handleClose = () => {
    getUnreadArticle();
    setOpen(false);
  };

  useEffect(() => {
    getPublishedArticlesByUser();
    setLang(i18n.language);
  }, []);

  useEffect(() => {
    getPublishedArticlesByUser();
  }, [reloadPublishArticle]);

  const chooseCategory = (e) => {
    setCurrentCategory(e.currentTarget.getAttribute("value"));
  };

  const displayCategory = (idCategory, imageName, unread) => {
    return memos.filter((a) => a.idCategory == idCategory).length <= 0 ? (
      <Category name={imageName} unread={unread} blackAndWhite={true} />
    ) : (
      <Category
        name={imageName}
        onclick={(e) => handleOpen(e)}
        idCategory={idCategory}
        unread={unread}
        blackAndWhite={false}
      />
    );
  };

  const getPublishedArticlesByUser = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/article/GetPublishedArticles`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setMemo(data);
        setReloadPublishArticle(false);
      });
  };

  const getPublishedArticles = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/article/GetPublishedArticles`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setMemo(data);
        setReloadPublishArticle(false);
      });
  };

  const getUnreadArticle = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/logger/LogArticle/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setUnreadArticle(data);
      })
      .then(() => {
        setUnreadArticleRH(0);
        setUnreadArticleRD(0);
        setUnreadArticleCasserole(0);
        setUnreadArticleFinance(0);
        setUnreadArticleFormation(0);
        setUnreadArticleInformatique(0);
        setUnreadArticleDeveloppement(0);
        setUnreadArticleMarketing(0);

        memos.forEach((m) => {
          if (!unreadArticle.some((u) => m.id == u.idObject)) {
            if (m.idCategory == 1) {
              setUnreadArticleRH((prev) => ++prev);
            }
            if (m.idCategory == 2) {
              setUnreadArticleRD((prev) => ++prev);
            }
            if (m.idCategory == 3) {
              setUnreadArticleCasserole((prev) => ++prev);
            }
            if (m.idCategory == 4) {
              setUnreadArticleFinance((prev) => prev + 1);
            }
            if (m.idCategory == 5) {
              setUnreadArticleFormation((prev) => prev + 1);
            }
            if (m.idCategory == 6) {
              setUnreadArticleInformatique((prev) => ++prev);
            }
            if (m.idCategory == 7) {
              setUnreadArticleDeveloppement((prev) => ++prev);
            }
            if (m.idCategory == 8) {
              setUnreadArticleMarketing((prev) => ++prev);
            }
          }
        });
      });
  };

  useEffect(() => {
    getUnreadArticle();
  }, [memos]);

  useEffect(() => {
    CategoryByLangage();
  }, [lang]);

  const CategoryByLangage = () => {
    if (userLang == "FR") {
      return (
        <>
          {displayCategory(1, imageRHFR, unreadArticleRH)}
          {displayCategory(2, imageRDFR, unreadArticleRD)}
          {displayCategory(3, imageCasserole, unreadArticleCasserole)}
          {displayCategory(4, imageFinanceFR, unreadArticleFinance)}
          {displayCategory(5, imageFormationFR, unreadArticleFormation)}
          {displayCategory(6, imageInformatiqueFR, unreadArticleInformatique)}
          {displayCategory(7, imageDeveloppementFR, unreadArticleDeveloppement)}
          {displayCategory(8, imageMarketing, unreadArticleMarketing)}
        </>
      );
    } else {
      return (
        <>
          {displayCategory(1, imageRHEN, unreadArticleRH)}
          {displayCategory(2, imageRDEN, unreadArticleRD)}
          {displayCategory(3, imageCasserole, unreadArticleCasserole)}
          {displayCategory(4, imageFinanceEN, unreadArticleFinance)}
          {displayCategory(5, imageFormationEN, unreadArticleFormation)}
          {displayCategory(6, imageInformatiqueEN, unreadArticleInformatique)}
          {displayCategory(7, imageDeveloppementEN, unreadArticleDeveloppement)}
          {displayCategory(8, imageMarketing, unreadArticleMarketing)}
        </>
      );
    }
  };

  return (
    <>
      {!Cookies.get("isAuthentificated") ? (
        <Login />
      ) : (
        <div>
          <div className="MemoHebdo__Filter">{<Filter />}</div>
          <div className="MemoHebdo__Categories"></div>
          <div className="MemoHebdo__ModalArea">
            <div className="MemoHebdo__GridCategory">{CategoryByLangage()}</div>
          </div>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="MemoHebdo__Modal">
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {memos
                    .filter((a) => a.idCategory == currentCategory)
                    .map((memo) =>
                      unreadArticle.some((ua) => ua.idObject == memo.id) ? (
                        <>
                          <Article
                            memo={memo}
                            unread={false}
                            relaod={setReloadPublishArticle}
                          />
                        </>
                      ) : (
                        <>
                          <Article
                            memo={memo}
                            unread={true}
                            reload={setReloadPublishArticle}
                          />
                        </>
                      )
                    )}
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
}
