import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import "../../css/EdditionArticleInProgress.css";
import LockIcon from "@mui/icons-material/Lock";
import Tooltip from "@mui/material/Tooltip";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import { useSelector } from "react-redux";

export function EdditionArticleInProgress(props) {
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [articleInEddition, setArticleInEddition] = useState([]);
  const [publishedArticle, setPublishedArticle] = useState([]);
  const [publishArticle, setPublishArticle] = useState([]);
  const { t, i18n } = useTranslation(["Articles"]);

  const year = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];

  const userState = useSelector((state) => state.user);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  /*  useEffect(() => {
    fetch("article/GetPublishedArticles").then((results)=>{
        return results.json();
    }).then(data  =>{
        setPublishArticle(data);
    })
    fetch("article/GetArticlesAlreadyPublished").then((results)=>{
      return results.json();
    }).then(data  =>{
      setPublishedArticle(data);

    })
    fetch("article/GetArticlesIneddition").then((results)=>{
      return results.json();
    }).then(data  =>{
      setArticleInEddition(data);
    })
},[])*/

  const getPublishedArticles = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/article/GetPublishedArticles`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setPublishArticle(data);
      });
  };

  const getArticlesIneddition = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/article/GetArticlesInEdition`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setArticleInEddition(data);
        props.setReload(false);
      });
  };

  useEffect(() => {
    getPublishedArticles();
    getArticlesIneddition();

    /*fetch("article/GetArticlesAlreadyPublished")
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setPublishedArticle(data);
      });*/
  }, [props.reloadArticle]);

  const GetArticlesAlreadyPublished = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/article/GetArticlesAlreadyPublished`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setPublishedArticle(data);
      });
  };

  const TreeItemByYear = (annee) => {
    const lang = i18n.language;
    return (
      <TreeItem nodeId={annee + 1000} label={annee}>
        {publishedArticle
          .filter(
            (a) =>
              new Date(a.publishedDate).getFullYear() >= annee &&
              new Date(a.endPublishedDate).getFullYear() < annee + 1
          )
          .map((article, i) => (
            <>
              <TreeItem
                nodeId={article.id}
                label={
                  article.idEmployeLock ? (
                    <div>
                      <Tooltip
                        title={"Lock by " + article.employeLock}
                        placement="right"
                        arrow
                      >
                        {lang == "fr" ? (
                          <span>
                            {article.titleFr} <LockIcon />
                          </span>
                        ) : (
                          <span>
                            {article.titleEn} <LockIcon />
                          </span>
                        )}
                      </Tooltip>
                    </div>
                  ) : lang == "fr" ? (
                    <span>{article.titleFr}</span>
                  ) : (
                    <span>{article.titleEn}</span>
                  )
                }
                onClick={(e) => props.selectArticle(article)}
              />
            </>
          ))}
      </TreeItem>
    );
  };

  const TreeItemArticle = (data, labelName, nodeId) => {
    const lang = i18n.language;
    return (
      <TreeItem nodeId={nodeId} label={labelName}>
        {data.map((article) => (
          <>
            <TreeItem
              nodeId={article.id}
              label={
                article.idEmployeLock ? (
                  <div>
                    <Tooltip
                      title={"Lock by " + article.employeLock}
                      placement="right"
                      arrow
                    >
                      {lang == "fr" ? (
                        <span>
                          {article.titleFr} <LockIcon />
                        </span>
                      ) : (
                        <span>
                          {article.titleEn} <LockIcon />
                        </span>
                      )}
                    </Tooltip>
                  </div>
                ) : lang == "fr" ? (
                  <span>{article.titleFr}</span>
                ) : (
                  <span>{article.titleEn}</span>
                )
              }
              onClick={(e) => props.selectArticle(article)}
            />
          </>
        ))}
      </TreeItem>
    );
  };

  return (
    <>
      <div className="EdditionInProgress__Main">
        <div className="EdditionInProgress__Title">
          <h3>{t("Articles")}</h3>
        </div>
        <div className="EdditionInProgress__Treeview">
          <Box
            sx={{ height: 270, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
          >
            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
              multiSelect
            >
              {TreeItemArticle(articleInEddition, t("InEddition"), 24500)}
              {TreeItemArticle(publishArticle, t("Published"), 26000)}

              <TreeItem
                nodeId={40000}
                label={t("AlreadyPublished")}
                onClick={GetArticlesAlreadyPublished}
              >
                {TreeItemByYear(2023)}
                {TreeItemByYear(2022)}
                {TreeItemByYear(2021)}
                {TreeItemByYear(2020)}
              </TreeItem>
            </TreeView>
          </Box>
        </div>
      </div>
    </>
  );
}

export default EdditionArticleInProgress;
