import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import StepEmailRecuperation from "./StepEmailRecuperation";
import Cookies from "js-cookie";

import { login, setLang, setToken } from "../../redux/actions";
import "../../css/EmailRecuperation.css";
import store from "../../redux/store";

export function EmailRecuperation(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isValid, setIsValid] = React.useState(false);
  const valueRef = useRef("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const { t, i18n } = useTranslation(["MFA"]);

  const steps = [
    t("RegisterEmail"),
    t("ValidateEmail"),
    t("RegisterPhone"),
    t("ValidatePhone"),
  ];

  useRef(() => {}, [isValid]);

  const isStepOptional = (step) => {
    return step === 2 || step === 3;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0) {
      fetch(`${process.env.REACT_APP_API_URL}/login/codeEmail/${email}`, {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
        .then((results) => {
          return results.text();
        })
        .then((data) => {
          setSecretCode(data);
        });
    }

    if (activeStep === 1 || activeStep === 3) {
      if (activeStep === 1) {
        saveEmail();
      }
      if (activeStep === 3) {
        savePhoneNumber();
      }
    }

    if (activeStep === 2) {
      fetch(`${process.env.REACT_APP_API_URL}/login/codeSMS/${phoneNumber}`, {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
        .then((results) => {
          return results.text();
        })
        .then((data) => {
          setSecretCode(data);
        });
    }
    if (activeStep === steps.length - 1 || activeStep === steps.length) {
      Cookies.set("isAuthentificated", true, { expires: 1 }, { secure: true });
      //Cookies.set("username", props.username, { expires: 1 }, { secure: true });
      localStorage.setItem("username", props.userName);
      localStorage.setItem("idSession", props.idSession);
      store.dispatch(login(props.userName, props.idSession));
      localStorage.setItem("token", props.token);
      store.dispatch(setToken(props.token));
      window.location.reload();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setIsValid(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 2);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const saveEmail = async () => {
    var dataUser = {
      username: props.username,
      email: email,
    };

    fetch(`${process.env.REACT_APP_API_URL}/employee/employe/SaveEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify(dataUser),
    }).then((results) => {
      return results.json();
    });
  };

  const savePhoneNumber = async () => {
    var dataUser = {
      username: props.username,
      phoneNumber: phoneNumber,
    };

    fetch(`${process.env.REACT_APP_API_URL}/employee/employe/SavePhoneNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify(dataUser),
    }).then((results) => {
      return results.json();
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const validateData = () => {
    var regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var regexPhoneNumber =
      /^(\(\+[0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})\-?([0-9]{4})(\/[0-9]{4})?$/gm;

    if (activeStep === 0) {
      if (valueRef.current.value !== "") {
        if (regexEmail.test(valueRef.current.value)) {
          console.log("test");
          setIsValid(true);
          setEmail(valueRef.current.value);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(false);
      }
    }
    if (activeStep === 1 || activeStep === 3) {
      if (valueRef.current.value !== "") {
        if (secretCode == valueRef.current.value) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(false);
      }
    }

    if (activeStep === 2) {
      if (valueRef.current.value !== "") {
        if (regexPhoneNumber.test(valueRef.current.value)) {
          setIsValid(true);
          setPhoneNumber(valueRef.current.value);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(false);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleNext}>Finish</Button>
          </Box>
        </React.Fragment>
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <div className="EmailRecuperation__Step">
              {activeStep === 0 && (
                <StepEmailRecuperation
                  label={"Email"}
                  description={t("ExplainationMFA")}
                  valueRef={valueRef}
                  validateData={validateData}
                />
              )}
              {activeStep === 1 && (
                <StepEmailRecuperation
                  label={"Code"}
                  description={t("ValidateEmailDescription")}
                  valueRef={valueRef}
                  validateData={validateData}
                />
              )}
              {activeStep === 2 && (
                <StepEmailRecuperation
                  label={"Phone"}
                  description={t("ExplainationMFA")}
                  valueRef={valueRef}
                  validateData={validateData}
                />
              )}
              {activeStep === 3 && (
                <StepEmailRecuperation
                  label={"Code"}
                  description={t("ValidatePhoneDescription")}
                  valueRef={valueRef}
                  validateData={validateData}
                />
              )}
              <div className="EmailRecuperation__Button">
                {activeStep !== steps.length && (
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                )}
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                {
                  <Button onClick={handleNext} disabled={!isValid}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                }
              </div>
            </div>
          </Box>
        </>
      )}
    </Box>
  );
}

export default EmailRecuperation;
