import React, { Suspense } from "react";
import { useEffect, useState, useRef, useReducer } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";

import "../../css/Filter.css";
import { useSelector } from "react-redux";

function SearchReport(props) {
  const [publishDate, setPublishDate] = useState("");
  const [endPublishDate, setEndPublishDate] = useState("");
  const { t, i18n } = useTranslation(["Articles"]);
  const [searchList, setSearchList] = useState([]);
  const [search, setSearch] = useState(false);
  const [word, setWord] = useState("");
  const ref = useRef(null);
  const [searchArticle, setSearchArticle] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [employee, setEmployee] = useState();
  const [employees, setEmployees] = useState([]);
  const [restaurant, setRestaurant] = useState();

  const [restaurants, setRestaurants] = useState([]);
  const [noEmplacement, setNoEmplacement] = useState();
  const [typeActivities, setTypeActivities] = useState([]);
  const [typeActivity, setTypeActivity] = useState([]);
  const [typeActivityText, setTypeActivityText] = useState();

  const userState = useSelector((state) => state.user);

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  useEffect(() => {
    GetRestaurants();
    GetActivities();
    GetInternalEmploye();
  }, []);

  const handleDelete = (chipToDelete) => () => {
    setSearchList((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSpace = (e) => {
    if (e.keyCode === 13) {
      setSearchList((prev) => [...prev, word]);
      setWord("");
      ref.current.value = "";
    }
  };

  const simulateKeyPress = (keyCode) => {
    const event = new KeyboardEvent("keydown", {
      keyCode: keyCode,
      bubbles: true,
      cancelable: true,
    });
    const targetElement = document.getElementById("search");
    if (targetElement && targetElement.value) {
      targetElement.dispatchEvent(event);
    }
  };

  /*useEffect(() => {
    handleSearch();
    GetActivities();
    if (searchList.length > 0) {
      //handleSearchByKeywords();
    } else if (publishDate || endPublishDate) {
      //handleSearchByDate();
    }
    setSearch(false);
  }, [search]);*/

  const handleSearch = async () => {
    console.log(typeActivity);
    var search = {
      restaurant: restaurant,
      typeActivity: typeActivity.id,
      endPublishDate: endPublishDate,
      publishDate: publishDate,
      Employe: employee,
      username: userState.username,
    };

    fetch(`${process.env.REACT_APP_API_URL}/Report/SearchReport`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(search),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        console.log(data);
        props.setReports(data);
      });
  };

  const handleChangeRestaurant = (e) => {
    setRestaurant(e.target.textContent);
    if (e.target.textContent) {
      setNoEmplacement(
        restaurants.filter((r) => r.nomEmplacement === e.target.textContent)[0]
          .noEmplacement
      );
    }
  };

  const handleChangeTypeActivities = (event) => {
    setTypeActivity(
      typeActivities.filter(
        (activite) => activite.textFr == event.target.textContent
      )[0]
    );
    setTypeActivityText(event.target.textContent);
  };

  const GetActivities = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Report/GetActivities/${userState.username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setTypeActivities(data);
      });
  };

  const GetInternalEmploye = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/employee/GetInternalEmploye`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        console.log(data);
        setEmployees(data);
      });
  };

  const GetRestaurants = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRestaurantsByUser/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRestaurants(data);
      });
  };

  return (
    <div>
      <Stack
        spacing={2}
        direction="row"
        className="Filter__Search"
        sx={{ m: 2 }}
      >
        <div className="Filter__date">
          <div className="Filter__date__datePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("From")}
                defaultValue={dayjs(publishDate)}
                value={dayjs(publishDate)}
                onChange={(newValue) => setPublishDate(newValue)}
                sx={{ width: "200px" }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("To")}
                defaultValue={dayjs(endPublishDate)}
                value={dayjs(endPublishDate)}
                onChange={(newValue) => setEndPublishDate(newValue)}
                sx={{ width: "200px" }}
              />
            </LocalizationProvider>
          </div>
        </div>

        <FormControl fullWidth>
          <Autocomplete
            options={employees} // Pass the full employee objects
            getOptionLabel={(option) => option.username} // Use userName for display
            onChange={(event, newValue) => setEmployee(newValue)} // Set the whole object
            sx={{ marginRight: "10px" }}
            value={employee} // Use the full object for value
            renderInput={(params) => (
              <TextField {...params} label={t("Employee")} />
            )}
          />
        </FormControl>

        <FormControl fullWidth>
          <Autocomplete
            id={t("Restaurants")}
            options={restaurants.map((r) => r.nomEmplacement)}
            onChange={(e) => handleChangeRestaurant(e)}
            sx={{ marginRight: "10px" }}
            value={restaurant ?? ""}
            renderInput={(params) => (
              <TextField {...params} label={t("Restaurants")} />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete
            id={t("TypeReport")}
            options={typeActivities.map((act) => act.textFr)}
            onChange={(e) => handleChangeTypeActivities(e)}
            sx={{ marginRight: "10px" }}
            value={typeActivityText ?? ""}
            renderInput={(params) => (
              <TextField {...params} label={t("TypeReport")} />
            )}
          />
        </FormControl>
        {/*
        <TextField
          margin="normal"
          name="search"
          label={t("Keywords")}
          type="text"
          id="search"
          autoComplete="current-password"
          onChange={(e) => setWord(e.currentTarget.value)}
          onKeyDown={(e) => handleSpace(e)}
          value={word}
          ref={ref}
        />
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {searchList ? (
            searchList.map((data) => {
              let icon;

              return (
                <ListItem key={data.key}>
                  <Chip
                    icon={icon}
                    label={data}
                    onDelete={handleDelete(data)}
                  />
                </ListItem>
              );
            })
          ) : (
            <></>
          )}
        </Paper>*/}
        <Button
          variant="contained"
          sx={{ backgroundColor: "#FFBC0F", color: "black" }}
          onClick={handleSearch}
        >
          {t("Search")}
        </Button>
      </Stack>
    </div>
  );
}

export default SearchReport;
