import React, { useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Link from "@mui/material/Link";

import "../../css/UploadedFile.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function UploadedFile(props) {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [attachments, setAttachments] = useState([]);
  const { t, i18n } = useTranslation(["Articles"]);

  const userState = useSelector((state) => state.user);

  const getAttachments = async () => {
    if (props.idArticle) {
      fetch(
        `${process.env.REACT_APP_API_URL}/article/GetAttachments/${props.idArticle}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setAttachments(data);
        });
    }
  };

  useEffect(() => {
    getAttachments();
  }, [props]);

  useEffect(() => {
    getAttachments();
  }, [props.reloadArticle]);

  useEffect(() => {}, [attachments]);

  const handleDelete = async (e) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/article/DeleteAttachment/${e.currentTarget.value}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {});
  };

  const handleOpenFile = async (e) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/article/DownloadAttachment/${e.target.textContent}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = e.target.textContent;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        window.open(url, "_blank");
      });
  };

  const attachment = () => {
    return (
      <Grid item xs={12} md={6}>
        <List dense={dense}>
          {attachments.map((attachment) => {
            return (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    value={attachment.id}
                    onClick={(e) => handleDelete(e)}
                    disabled={props.readOnly}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src="/static/images/avatar/2.jpg">
                    {attachment.lang}
                  </Avatar>
                </ListItemAvatar>
                <Link
                  onClick={(e) => handleOpenFile(e)}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemText primary={attachment.fileName} />
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  };

  return (
    <div className="UploadedFile__Main">
      <h5>{t("FileUploaded")}</h5>

      {attachments.length > 0 ? (
        attachment()
      ) : (
        <p>
          <em>{t("NoFilesAttached")}</em>
        </p>
      )}
    </div>
  );
}

export default UploadedFile;
