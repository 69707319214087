import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./reducer";
import storageSession from "redux-persist/es/storage/session";
import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import thunk from "redux-thunk";
import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
  key: "user",
  version: 1,
  storage: storageSession,
  transforms: [
    encryptTransform({
      secretKey: "coraSecretKeyTest",
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const reducer = combineReducers({
  user: userSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
