import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import { disable } from "workbox-navigation-preload";
import { useSelector } from "react-redux";

const steps = [
  "Enter your username",
  "validate code",
  "Enter your new password",
];

export function ForgotPassword() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isInternal, setIsInternal] = React.useState(false);
  const [secretCode, setSecretCode] = useState("");
  const { t, i18n } = useTranslation(["Login"]);
  const [username, setUsername] = useState("");
  const [code, setCode] = useState();
  const [newPassword1, setNewPassword1] = useState();
  const [newPassword2, setNewPassword2] = useState();
  const [email, setEmail] = useState();
  const [disable, setDisable] = useState(false);

  const userState = useSelector((state) => state.user);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const saveNewPassword = async () => {
    var dataUser = {
      username: username,
      password: newPassword1,
    };

    fetch(`${process.env.REACT_APP_API_URL}/employee/SaveNewPassword`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataUser),
    })
      .then((results) => {
        return results.json();
      })
      .then(async (data) => {
        console.log("success");
      });
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep == 0) {
      IsInternal();
      getEmail();
      if (isInternal) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      } else {
        sendCode();
      }
    }
    if (activeStep == 1) {
      if (code.trim() !== secretCode) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }
    if (activeStep == 2) {
      if (newPassword1 === newPassword2) {
        saveNewPassword();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsInternal(false);
  };

  const sendCode = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/login/codeEmail/${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.text();
      })
      .then((data) => {
        setSecretCode(data);
      });
  };

  const IsInternal = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/employee/GetIsInternal/${username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setIsInternal(data);
      });
  };

  const getEmail = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/employee/GetEmploye/${username}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setEmail(data.email);
      });
  };

  useEffect(() => {
    if (username) {
      getEmail();
    }
  }, [username]);

  const handleClose = () => {
    window.location.reload();
  };
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleClose}>Close</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Please enter your username
                </Typography>
                <TextField
                  style={{ width: "300px", margin: "5px" }}
                  type="text"
                  label="username"
                  variant="outlined"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </>
            )}
            {activeStep === 1 && (
              <>
                {isInternal ? (
                  <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      {t("ForgotPasswordInternal")}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      Please enter the verification code
                    </Typography>
                    <TextField
                      style={{ width: "300px", margin: "5px" }}
                      type="text"
                      label="code"
                      variant="outlined"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                  </>
                )}
              </>
            )}
            {activeStep === 2 && (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  enter your new password :
                </Typography>
                <TextField
                  style={{ width: "300px", margin: "5px" }}
                  type="password"
                  label="new password"
                  variant="outlined"
                  value={newPassword1}
                  onChange={(e) => {
                    setNewPassword1(e.target.value);
                  }}
                />
                <TextField
                  style={{ width: "300px", margin: "5px" }}
                  type="password"
                  label="repeat new password"
                  variant="outlined"
                  value={newPassword2}
                  onChange={(e) => {
                    setNewPassword2(e.target.value);
                  }}
                />
              </>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button onClick={handleNext} disabled={disable}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}

export default ForgotPassword;
