import React from "react";
import { EditorSpecialMessage } from "./EditorSpecialMessage";
import Login from "../Login";
import Cookies from "js-cookie";

export function SpecialMessage() {
  return (
    <>
      {!Cookies.get("isAuthentificated") ? <Login /> : <EditorSpecialMessage />}
    </>
  );
}

export default SpecialMessage;
