import React, { useEffect, useState } from "react";
import { PreviewRepport } from "./PreviewRepport";
import ReportItemDashboard from "./ReportItemDashboard";
import { hash, hashAuthentifcation } from "../../utils/Hash";
import IDAPIKEY from "../../utils/IDAPIKEY";
import Cookies from "js-cookie";
import SearchReport from "./SearchReport";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";

function DashboardRepport() {
  const [reports, setReports] = useState([]);
  const [page, setPage] = React.useState(1);

  const userState = useSelector((state) => state.user);

  const userLang = useSelector((state) => state.user.lang);

  useEffect(() => {
    GetCompletedReports();
  }, []);

  const GetCompletedReports = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Report/GetCompletedReports/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setReports(data);
      });
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <SearchReport setReports={setReports} />
      {reports.slice((page - 1) * 10, page * 10).map((report) => (
        <ReportItemDashboard
          nomEmplacement={report.nomEmplacement}
          date={report.dateComplete}
          dateEvent={report.dateEvenement}
          username={report.username}
          typeActivite={
            userLang == "EN" ? report.titleRepportEn : report.titleRepportFr
          }
          report={report}
        />
      ))}
      <Stack spacing={2}>
        <Pagination
          count={
            reports.length % 10 == 0
              ? Math.floor(reports.length / 10)
              : Math.floor(reports.length / 10) + 1
          }
          page={page}
          onChange={handleChangePage}
        />
      </Stack>
    </div>
  );
}

export default DashboardRepport;
