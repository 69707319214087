import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import "../../css/ArticleProperties.css";
import { useEffect, useState, Suspense, useReducer } from "react";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 800,
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function ArticleProperties(props) {
  const [open, setOpen] = React.useState(false);
  const [publishDate, setPublishDate] = useState("");
  const [endPublishDate, setEndPublishDate] = useState("");
  const [signataires, setSignataires] = React.useState([]);
  const [signataire, setSignataire] = React.useState([]);
  const [internalDepartments, setInternalDepartments] = React.useState([]);
  const [internalDepartment, setInternalDepartment] = React.useState();
  const [externalDepartments, setExternalDepartments] = React.useState([]);
  const [externalDepartment, setExternalDepartment] = React.useState([]);
  const [provinces, setProvinces] = useState([]);
  const [TreeViewProvince, setTreeViewProvince] = useState([]);
  const [author, setAuthor] = useState();
  const [checkedState, setCheckedState] = useState(
    new Array(externalDepartments.length).fill(false)
  );
  const [idSignataire, setIdSignataire] = useState(0);
  const [idDepartment, setIdDepartment] = useState([]);
  const [idDepartmentInterne, setIdDepartmentInterne] = useState("");
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [noEmplacement, setNoEmplacement] = useState([]);
  const [emplacementArticle, setEmplacementArticle] = useState([]);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState([]);
  const { t, i18n } = useTranslation(["Articles"]);

  const userState = useSelector((state) => state.user);

  const getInternalDepartment = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/article/GetCategoryArticle`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setInternalDepartments(data);
      });
  };

  const getExternalDepartment = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetExternalDepartment`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setExternalDepartments(data);
        setCheckedState(new Array(externalDepartments.length).fill(false));
      });
  };

  const getProvince = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/Localization/GetProvince`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setProvinces(data);
        TreeProvince(provinces);
      });
  };

  const TreeProvince = (data) => {
    var tree = [];

    let ids = 0;
    for (let i = 0; i < data.length; i++) {
      if (!tree.some((p) => p.paysFr == data[i].paysFr)) {
        tree.push({
          id: ids++,
          paysFr: data[i].paysFr,
          paysEn: data[i].paysEn,
          children: [],
        });
      }
      for (let p = 0; i < data.length; i++) {
        if (!tree[p].children.some((p) => p.provinceFr == data[i].provinceFr)) {
          tree[p].children.push({
            id: ids++,
            parent: tree[p].id,
            provinceFr: data[i].provinceFr,
            provinceEn: data[i].provinceEn,
            children: [],
          });
        }
        for (let j = 0; j < tree[p].children.length; j++) {
          if (
            tree[p].children[j].provinceFr == data[i].provinceFr &&
            !tree[p].children[j].children.some(
              (r) => r.regionFr == data[i].regionFr
            )
          ) {
            tree[p].children[j].children.push({
              id: ids++,
              parent: tree[p].children[j].id,
              regionFr: data[i].regionFr,
              regionEn: data[i].regionEn,
              children: [],
            });
          }
          for (let k = 0; k < tree[p].children[j].children.length; k++) {
            if (
              tree[p].children[j].provinceFr == data[i].provinceFr &&
              tree[p].children[j].children[k].regionFr == data[i].regionFr &&
              !tree[p].children[j].children[k].children.some(
                (e) => e.nomEmplacement == data[i].nomEmplacement
              )
            ) {
              tree[p].children[j].children[k].children.push({
                id: ids++,
                parent: tree[p].children[j].children[k].id,
                nomEmplacement: data[i].nomEmplacement,
                noEmplacement: data[i].noEmplacement,
              });
            }
          }
        }
      }
    }
    setTreeViewProvince(tree);
    return tree;
  };

  const getsignataire = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/employee/GetSignataire`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setSignataires(data);
      });
  };

  const setProperties = async () => {
    if (props.activeArticleProperties) {
      fetch(
        `${process.env.REACT_APP_API_URL}/article/GetCategoryArticleById/${props.activeArticleProperties.id}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setInternalDepartment(data.textFr);
        });
      fetch(
        `${process.env.REACT_APP_API_URL}/employee/GetEmployeById/${props.activeArticleProperties.idSignataire}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setSignataire(data.userName);
        });

      await fetch(
        `${process.env.REACT_APP_API_URL}/article/GetExternalDepartmentArticle/${props.activeArticleProperties.id}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setExternalDepartment(data);
          let idExternalDepartment = [];
          data.forEach((element) => {
            idExternalDepartment.push(element.idDepartement);
          });
          setIdDepartment(idExternalDepartment);
        });
      await fetch(
        `${process.env.REACT_APP_API_URL}/article/GetEmplacementArticle/${props.activeArticleProperties.id}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setEmplacementArticle(data);
          let noLocation = [];
          data.forEach((element) => {
            noLocation.push(element.idEmplacement);
          });
          setNoEmplacement(noLocation);
        });
      fetch(
        `${process.env.REACT_APP_API_URL}/article/GetDateArticleById/${props.activeArticleProperties.id}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setPublishDate(data.publicationDate);
          setEndPublishDate(data.endPublicationDate);
        });
    }
  };

  const InitcheckedProvinces = () => {
    let leaves = [];
    getLeafNodes(leaves, bfsSearch(TreeViewProvince, 0));
    const ToBeChecked = [];
    const ToBeIndeterminate = [];

    for (let j = 0; j < leaves.length; j++) {
      if (
        emplacementArticle.some(
          (e) => e.idEmplacement == leaves[j].noEmplacement
        )
      ) {
        ToBeChecked.push(leaves[j].id);
      }
    }

    setSelectedNodes([]);
    leaves.forEach((l) => {
      if (emplacementArticle.some((e) => e.idEmplacement == l.noEmplacement)) {
        const fathers = getAllFathers(l.id);
        for (let i = 0; i < fathers.length; i++) {
          if (
            isAllChildrenChecked(
              bfsSearch(TreeViewProvince, fathers[i]),
              ToBeChecked
            )
          ) {
            ToBeChecked.push(fathers[i]);
          } else if (
            isAtLeastOneChildIsChecked(
              bfsSearch(TreeViewProvince, fathers[i]),
              ToBeChecked
            )
          ) {
            ToBeIndeterminate.push(fathers[i]);
          }
        }

        setSelectedNodes(ToBeChecked);
        setIndeterminateCheckbox(ToBeIndeterminate);
      }
    });
  };

  useEffect(() => {
    setSelectedNodes([]);
    setIndeterminateCheckbox([]);
    getProvince();
    InitcheckedProvinces();
  }, [open]);

  useEffect(() => {
    if (props.activeArticleProperties) {
      setIdDepartmentInterne(props.activeArticleProperties.idCategory);
      InitcheckedProvinces();
    }
  }, [props.activeArticleProperties]);

  useEffect(() => {
    InitializeDepartmentCheckbox();
  }, [externalDepartment]);

  const handleChange = (e, position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    if (!checkedState[position] == true) {
      setIdDepartment((prevId) => prevId.concat(e.currentTarget.value));
    } else {
      setIdDepartment((prevId) =>
        prevId.filter((i) => i !== e.currentTarget.value)
      );
    }
    setCheckedState(updatedCheckedState);
  };

  const handleChangeSignataire = (event) => {
    setSignataire(event.target.value);
  };

  const handleChangeDepartementInterne = (event) => {
    setInternalDepartment(event.target.value);
  };

  const handleSaveProperties = async () => {
    if (saveValidation()) {
      var uniqueRestaurent = noEmplacement.filter(
        (x, i) => noEmplacement.indexOf(x) === i
      );

      var uniqueDepartment = idDepartment.filter(
        (x, i) => idDepartment.indexOf(x) === i
      );

      let publishDateETA = "";
      let endPublicationDateETA = "";
      if (publishDate) {
        publishDateETA = new Date(publishDate);
      }
      if (endPublishDate) {
        endPublicationDateETA = new Date(endPublishDate);
      }

      var articleData = {
        id: props.activeArticleProperties.id,
        titleFr: props.activeArticleProperties.titleFr,
        titleEn: props.activeArticleProperties.titleEn,
        textFr: props.activeArticleProperties.textFr,
        textEn: props.activeArticleProperties.textEn,
        idAuthor: 0,
        author: userState.username,
        idSignataire:
          idSignataire || props.activeArticleProperties.idSignataire,
        idEmployeLock: null,
        employeLock: "",
        idCategory: idDepartmentInterne || 0,
        creationDate: "2024-08-01T18:39:53.444Z",
        publicationDate: publishDateETA || null,
        endPublicationDate: endPublicationDateETA || null,
        externalDepartmentId: 0,
        idEmplacement: uniqueRestaurent,
        idExternalDepartment: uniqueDepartment,
      };

      fetch(`${process.env.REACT_APP_API_URL}/article/SaveArticleProperties`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(articleData),
      })
        .then((results) => {
          return results.text();
        })
        .then((data) => {
          handleClose();
        });
    }
  };
  const handleExpandClick = (event) => {
    // prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  const handleOpen = async () => {
    props.saveArticle();
    setIndeterminateCheckbox([]);
    setSelectedNodes([]);
    setInternalDepartment();
    setIdDepartment();
    setSignataire();

    await InitializeDepartmentCheckbox();
    await getInternalDepartment();
    await getsignataire();
    await getExternalDepartment();
    await getProvince();
    await setProperties();
    await setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const InitializeDepartmentCheckbox = () => {
    let checkboxstate = [];
    for (let i = 0; i < externalDepartments.length; i++) {
      for (let j = 0; j < externalDepartment.length; j++) {
        if (externalDepartments[i].id == externalDepartment[j].idDepartement) {
          checkboxstate[i] = true;
          break;
        } else {
          checkboxstate[i] = false;
        }
      }
    }
    setCheckedState(checkboxstate);
  };

  const handleNodeSelect = async (event, nodeId) => {
    event.stopPropagation();
    const allChild = getAllChild(nodeId);
    const fathers = getAllFathers(nodeId);

    const selectedChild = [];
    selectedNodes.forEach((nodeId) => {
      if (bfsSearch(TreeViewProvince, nodeId).noEmplacement) {
        selectedChild.push(bfsSearch(TreeViewProvince, nodeId).noEmplacement);
      }
    });
    setNoEmplacement((prev) => prev.concat(selectedChild));

    var uniqueRestaurent = noEmplacement.filter(
      (x, i) => noEmplacement.indexOf(x) === i
    );
    setNoEmplacement(uniqueRestaurent);

    if (selectedNodes.includes(nodeId)) {
      // Need to de-check
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
      );
    } else {
      // Need to check
      const ToBeChecked = allChild;
      const ToBeIndeterminate = [];

      for (let i = 0; i < fathers.length; ++i) {
        if (
          isAllChildrenChecked(
            bfsSearch(TreeViewProvince, fathers[i]),
            ToBeChecked
          )
        ) {
          ToBeChecked.push(fathers[i]);
        } else if (
          isAtLeastOneChildIsChecked(
            bfsSearch(TreeViewProvince, fathers[i]),
            ToBeChecked
          )
        ) {
          ToBeIndeterminate.push(fathers[i]);
        }
      }
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.concat(ToBeChecked)
      );
      setIndeterminateCheckbox((prev) => prev.concat(ToBeIndeterminate));
    }

    if (selectedNodes.includes(nodeId)) {
      if (allChild.length > 1) {
        let leafNodes = [];
        getLeafNoEmplacement(leafNodes, bfsSearch(TreeViewProvince, nodeId));

        var diff = noEmplacement.filter((x) => leafNodes.indexOf(x) === -1);
        await setNoEmplacement(diff);
      } else {
        await setNoEmplacement((prev) =>
          prev.filter(
            (p) => p !== bfsSearch(TreeViewProvince, nodeId).noEmplacement
          )
        );
      }
    } else {
      if (allChild.length > 1) {
        let leafNodes = [];
        getLeafNoEmplacement(leafNodes, bfsSearch(TreeViewProvince, nodeId));

        if (noEmplacement.length == 0) {
          setNoEmplacement(leafNodes);
        } else {
          var diff = leafNodes.filter((x) => !noEmplacement.includes(x));
          await setNoEmplacement((prev) => prev.concat(diff));
        }
      } else {
        await setNoEmplacement((prev) =>
          prev.concat(bfsSearch(TreeViewProvince, nodeId).noEmplacement)
        );
      }
    }
  };

  function getLeafNoEmplacement(leafNodes, obj) {
    if (obj.children) {
      obj.children.forEach(function (child) {
        getLeafNoEmplacement(leafNodes, child);
      });
    } else {
      leafNodes.push(obj.noEmplacement);
    }
  }

  function getLeafNodes(leafNodes, obj) {
    if (obj.children) {
      obj.children.forEach(function (child) {
        getLeafNodes(leafNodes, child);
      });
    } else {
      leafNodes.push(obj);
    }
  }

  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.id === targetId) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  const getAllChild = (id) => {
    return getAllIds(bfsSearch(TreeViewProvince, id));
  };

  const getAllFathers = (id, list = []) => {
    const node = bfsSearch(TreeViewProvince, id);
    list.push(node.parent);
    if (node.parent) {
      return getAllFathers(node.parent, list);
    }
    return list;
  };

  function isAllChildrenChecked(node, test) {
    const leafChild = [];
    getLeafNodes(leafChild, node);

    const nodeIdIndex = leafChild.indexOf(node.id);
    leafChild.splice(nodeIdIndex, 1);

    /*const allChild = getAllChild(node.id);
    const nodeIdIndex = allChild.indexOf(node.id);
    allChild.splice(nodeIdIndex, 1);*/

    for (let i = 0; i < leafChild.length; i++) {
      if (test.indexOf(leafChild[i].id) === -1) {
        return false;
      }
    }
    return true;
  }

  function isAtLeastOneChildIsChecked(node, list) {
    const allChild = getAllChild(node.id);
    for (let i = 0; i < allChild.length; i++) {
      if (list.includes(allChild[i])) {
        return true;
      }
    }
    return false;
  }

  function getAllIds(node, idList = []) {
    idList.push(node.id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }

  const handleClearDate = () => {
    setPublishDate("");
    setEndPublishDate("");
    props.setPublicationDate("");
    props.setEndPublicationDate("");
  };

  const saveValidation = () => {
    if (publishDate && endPublishDate) {
      let beginDate = new Date(publishDate).getTime();
      let endDate = new Date(endPublishDate).getTime();
      if (beginDate > endDate) {
        alert("veuillez entrer des dates valides svp");
        return false;
      }
    }
    if (publishDate) {
      if (idDepartment.length <= 0) {
        alert("veuillez selectionner au moins un departement externe svp");
        return false;
      }
      if (noEmplacement.length <= 0) {
        alert("veuillez selectionner au moins une province svp");
        return false;
      }
    }
    if (!signataire) {
      alert("veuillez selectionner un signataire svp");
      return false;
    }
    if (!internalDepartment) {
      alert("veuillez selectionner une categorie svp");
      return false;
    }
    if (!publishDate && endPublishDate) {
      alert("veuillez selectionner une date de publication valide svp");
      return false;
    }
    return true;
  };

  return (
    <>
      <Suspense>
        <Button
          onClick={handleOpen}
          disabled={props.activeArticleProperties ? false : true}
        >
          {t("Properties")}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="ArticleProperties__Modal">
            <div className="ArticleProperties__date">
              <div className="ArticleProperties__date__datePicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <label>{t("PublicationDate")}</label>
                  <DateTimePicker
                    defaultValue={dayjs(publishDate)}
                    value={dayjs(publishDate)}
                    onChange={(newValue) => setPublishDate(newValue)}
                    disabled={props.readOnly}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <label>{t("EndPublishedDate")}</label>
                  <DateTimePicker
                    defaultValue={dayjs(endPublishDate)}
                    value={dayjs(endPublishDate)}
                    onChange={(newValue) => setEndPublishDate(newValue)}
                    disabled={props.readOnly}
                  />
                </LocalizationProvider>
              </div>
              <div className="ArticleProperties__date__button">
                <Button
                  variant="contained"
                  onClick={handleClearDate}
                  disabled={props.readOnly}
                >
                  {t("ClearDate")}
                </Button>
              </div>
            </div>
            <div className="ArticleProperties__Visibility">
              <div className="ArticleProperties__Province">
                <h3>{t("Restaurants")}</h3>

                <div>
                  {TreeViewProvince.map((pays) => (
                    <TreeView
                      multiSelect
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      selected={selectedNodes}
                    >
                      <TreeItem
                        key={pays.id}
                        nodeId={pays.id}
                        onClick={handleExpandClick}
                        label={
                          <>
                            <Checkbox
                              checked={selectedNodes.indexOf(pays.id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              onClick={(event) =>
                                handleNodeSelect(event, pays.id)
                              }
                              disabled={props.readOnly}
                              indeterminate={
                                indeterminateCheckbox.indexOf(pays.id) !== -1
                              }
                            />
                            {pays.paysFr}
                          </>
                        }
                      >
                        {pays.children.map((p) => (
                          <TreeItem
                            key={p.id}
                            nodeId={p.id}
                            onClick={handleExpandClick}
                            label={
                              <>
                                <Checkbox
                                  checked={selectedNodes.indexOf(p.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  onClick={(event) =>
                                    handleNodeSelect(event, p.id)
                                  }
                                  disabled={props.readOnly}
                                  indeterminate={
                                    indeterminateCheckbox.indexOf(p.id) !== -1
                                  }
                                />
                                {p.provinceFr}
                              </>
                            }
                          >
                            {p.children.map((r) => (
                              <TreeItem
                                key={r.id}
                                nodeId={r.id}
                                onClick={handleExpandClick}
                                label={
                                  <>
                                    <Checkbox
                                      checked={
                                        selectedNodes.indexOf(r.id) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      onClick={(event) =>
                                        handleNodeSelect(event, r.id)
                                      }
                                      disabled={props.readOnly}
                                      indeterminate={
                                        indeterminateCheckbox.indexOf(r.id) !==
                                        -1
                                      }
                                    />
                                    {r.regionFr}
                                  </>
                                }
                              >
                                {r.children.map((e) => (
                                  <TreeItem
                                    key={e.id}
                                    nodeId={e.id}
                                    onClick={handleExpandClick}
                                    label={
                                      <>
                                        <Checkbox
                                          checked={
                                            selectedNodes.indexOf(e.id) !== -1
                                          }
                                          tabIndex={-1}
                                          disableRipple
                                          onClick={(event) =>
                                            handleNodeSelect(event, e.id)
                                          }
                                          value={e.noEmplacement}
                                          disabled={props.readOnly}
                                        />
                                        {e.nomEmplacement}
                                      </>
                                    }
                                  ></TreeItem>
                                ))}
                              </TreeItem>
                            ))}
                          </TreeItem>
                        ))}
                      </TreeItem>
                    </TreeView>
                  ))}
                </div>
              </div>
              <div className="ArticleProperties__Department">
                <h3>{t("Reader")}</h3>

                {externalDepartments.map((d, index) => (
                  <div>
                    <Checkbox
                      checked={checkedState[index]}
                      onChange={(e) => handleChange(e, index)}
                      inputProps={{ "aria-label": "controlled" }}
                      value={d.id}
                      disabled={props.readOnly}
                    />
                    <label>{d.textFr}</label>
                  </div>
                ))}
              </div>
            </div>
            <Box sx={{ minWidth: 120 }}>
              <h3>{t("Signatory")}</h3>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("Signatory")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={signataire}
                  label={t("Signatory")}
                  onChange={handleChangeSignataire}
                  disabled={props.readOnly}
                >
                  {signataires.map((s) => (
                    <MenuItem
                      id={s.noEmploye}
                      value={s.userName}
                      onClick={(e) => setIdSignataire(e.target.id)}
                    >
                      {s.name + " " + s.lastName + " (" + s.userName + ")"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <h3>{t("CategoryArticle")}</h3>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("CategoryArticle")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={internalDepartment}
                  label={t("CategoryArticle")}
                  onChange={handleChangeDepartementInterne}
                  disabled={props.readOnly}
                >
                  {internalDepartments.map((department) => (
                    <MenuItem
                      id={department.id}
                      value={department.textFr}
                      onClick={(e) => setIdDepartmentInterne(e.target.id)}
                    >
                      {department.textFr}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  onClick={handleSaveProperties}
                  disabled={props.readOnly}
                >
                  {t("Save")}
                </Button>
              </FormControl>
            </Box>
          </Box>
        </Modal>
      </Suspense>
    </>
  );
}

export default ArticleProperties;
