import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../../css/ReportItemDashboard.css";
import MyDocument from "../Common/CreatePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { AccessRight } from "../../utils/AccessRight";
import { FormatDateTime } from "../../utils/formateDate";
import { useMediaQuery, useTheme } from "@mui/material";

function ReportItemDashboard(props) {
  const { t } = useTranslation(["Articles"], ["Common"]);
  const [report, setReport] = useState();
  const [isAdminReport, setIsAdminReport] = useState(false);
  const userState = useSelector((state) => state.user);
  const [employe, setEmploye] = useState();
  const userLang = useSelector((state) => state.user.lang);

  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );

  /*const handleDownload = () => {
    console.log(props.report);
  };

  const handlePreview = () => {
    return (
      <PDFViewer>
        <MyDocument report={report} />
      </PDFViewer>
    );
  };*/

  const getEmployeAccesRight = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/employee/GetEmployeAccessRight/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setEmploye(data);
        if (
          employe.some(
            (e) =>
              e.noAcessRight === AccessRight.REPORT_ADMIN ||
              e.noAcessRight === AccessRight.SYSTEM_ADMIN
          )
        ) {
          setIsAdminReport(true);
        }
      });
  };

  const handleReopen = async () => {
    console.log(props.report.idActivite);
    var acivities = {
      username: userState.username,
      id: props.report.idActivite,
    };

    fetch(`${process.env.REACT_APP_API_URL}/Report/ReopenReport`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(acivities),
    }).then((results) => {
      return results.json();
    });
  };

  useEffect(() => {
    getEmployeAccesRight();
  }, []);

  useEffect(() => {
    if (employe) {
      const adminRights = [AccessRight.REPORT_ADMIN, AccessRight.SYSTEM_ADMIN];
      const isAdmin = employe.some((e) => adminRights.includes(e.noAcessRight));
      setIsAdminReport(isAdmin);
    }
  }, [employe]);

  useEffect(() => {
    GetReport();
  }, [props.report]);

  const GetReport = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Report/GetReport/${props.report.idActivite}/${props.report.idTypeActivite}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        console.log(report);
        setReport(data);
      });
  };

  return (
    <div className="ReportItemDashboard__main">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: isMobile ? 1 : 2,
        }}
      >
        <Paper elevation={2}>
          <div className="ReportItemDashboard__DashboardReportInfo">
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              <b>{props.typeActivite}</b>
            </Box>
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              {props.nomEmplacement}
            </Box>
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              <b>{props.username}</b>
            </Box>
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              {" "}
              {FormatDateTime(props.date)}{" "}
            </Box>
            <Box className="ReportItemDashboard__DashboardReportInfo__BOX">
              {FormatDateTime(props.dateEvent)}{" "}
            </Box>
            <div className="ReportItemDashboard__DashboardReportButon">
              {isAdminReport && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#FFBC0F",
                    color: "black",
                  }}
                  onClick={handleReopen}
                >
                  {t("Reopen")}
                </Button>
              )}
              {/*
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFBC0F",
                  color: "black",
                  marginLeft: 5,
                }}
                onClick={handlePreview}
              >
                {t("Preview")}
              </Button>*/}

              {report && (
                <PDFDownloadLink
                  document={<MyDocument report={report} userLang={userLang} />}
                  fileName={
                    userLang == "EN"
                      ? props.report.idActivite +
                        "_" +
                        props.report.titleRepportEn +
                        ".pdf"
                      : props.report.idActivite +
                        "_" +
                        props.report.titleRepportFr +
                        ".pdf"
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#FFBC0F",
                          color: "black",
                          marginLeft: 5,
                        }}
                      >
                        {t("Download")}
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
}

export default ReportItemDashboard;
