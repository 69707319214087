import { createSlice } from "@reduxjs/toolkit";

/*
const ipAdress = () => {
  getLocalIP()
    .then((ip) => {
      return ip;
    })
    .catch((err) => {
      console.error("Error getting IP:", err);
      //setError(err.message);
    });
};*/

const getLocalIP = () => {
  return new Promise((resolve, reject) => {
    const RTCPeerConnection =
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
    if (!RTCPeerConnection) {
      console.error("WebRTC not supported by this browser.");
      return reject(new Error("WebRTC is not supported by this browser."));
    }

    const rtc = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    });

    rtc.createDataChannel(""); // create a bogus data channel

    rtc.onicecandidate = (event) => {
      if (event && event.candidate && event.candidate.candidate) {
        const candidate = event.candidate.candidate;
        const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
        const ipMatch = ipRegex.exec(candidate);
        if (ipMatch) {
          resolve(ipMatch[1]);
          /*if (candidate.includes("typ srflx")) {
            setPublicIP(ipMatch[1]);
          } else {
            setIP(ipMatch[1]);
          }*/
          rtc.close();
        }
      }
    };

    rtc
      .createOffer()
      .then((offer) => rtc.setLocalDescription(offer))
      .catch((err) => {
        console.error("Error creating offer:", err);
        reject(err);
      });
  });
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: localStorage.getItem("username"),
    idSession: localStorage.getItem("idSession"),
    token: localStorage.getItem("token"),
    email: "",
    lang: localStorage.getItem("lang"),
    isAuthentificated: localStorage.getItem("idSession") ? true : false,
    ipAdress: getLocalIP().then((value) => {
      return value;
    }),
  },
  reducers: {
    login: (state, action) => {
      state.username = action.payload.username;
      state.idSession = action.payload.idSession;
    },
    loginAs: (state, action) => {
      state.username = action.payload;
    },
    logout: (state, action) => {
      state.username = "";
      state.idSession = "";
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});
