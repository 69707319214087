import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Avatar,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import logo from "../resources/logo-cora_en.png";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AccessRight } from "../utils/AccessRight";
import store from "../redux/store";
import { login, setLang } from "../redux/actions";

const settings = ["Langage"];

export function MenuBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElReportMenu, setAnchorElReportMenu] = useState(null);
  const [anchorElLangage, setAnchorElLangage] = useState(null);
  const [anchorElArticleMenu, setAnchorElArticleMenu] = useState(null);
  const [anchorElAdminMenu, setAnchorElAdminMenu] = useState(null);
  const [employe, setEmploye] = useState([]);
  const [canEditArticle, setCanEditArticle] = useState(false);
  const [canWriteSpecialMessage, setCanWriteSpecialMessage] = useState(false);
  const [canModifyAPI, setCanModifyAPI] = useState(false);
  const [canModifyEmployee, setCanModifyEmployee] = useState(false);
  const [canAccessReport, setCanAccessReport] = useState(false);
  const [isAdminReport, setIsAdminReport] = useState(false);
  const [isFranchise, setIsfranchise] = useState(false);
  const [isInternalEmploye, setIsInternalEmploye] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const openReport = Boolean(anchorElReportMenu);
  const openLang = Boolean(anchorElLangage);
  const openArticleMenu = Boolean(anchorElArticleMenu);
  const openAdminMenu = Boolean(anchorElAdminMenu);
  const { t, i18n } = useTranslation(["Menu"]);
  const pages = []; // List your pages here if needed
  const userState = useSelector((state) => state.user);

  const departementInterne = [
    1, 2, 3, 4, 5, 6, 12, 13, 15, 16, 17, 18, 19, 20, 21, 25,
  ];

  useEffect(() => {
    getEmployeAccessRight();
  }, []);

  useEffect(() => {
    console.log(employe);
    if (employe.length) {
      setCanEditArticle(
        employe.some((e) => e.noAcessRight === AccessRight.MEMOHEBDO_EDITEUR)
      );
      setCanWriteSpecialMessage(
        employe.some((e) => e.noAcessRight === AccessRight.CASSEROLES_ADMIN)
      );
      setCanModifyAPI(
        employe.some((e) => e.noAcessRight === AccessRight.SYSTEM_ADMIN)
      );
      setCanModifyEmployee(
        employe.some((e) => e.noAcessRight === AccessRight.UserManagement)
      );
      setCanAccessReport(
        employe.some(
          (e) =>
            e.noAcessRight === AccessRight.REPORT_ADMIN ||
            e.noAcessRight === AccessRight.REPORT_EDDITOR
        )
      );
      setIsAdminReport(
        employe.some((e) => e.noAcessRight === AccessRight.REPORT_ADMIN)
      );
      setIsManager(employe.some((e) => e.idDepartement === 22));
      setIsfranchise(employe.some((e) => e.idDepartement === 7));
      setIsInternalEmploye(
        employe.some((e) => departementInterne.includes(e.idDepartement))
      );
    }
  }, [employe]);

  const handleClickNav = (event) => setAnchorElNav(event.currentTarget);
  const handleClickLangageMenu = (event) =>
    setAnchorElLangage(event.currentTarget);
  const handleClickReportMenu = (event) =>
    setAnchorElReportMenu(event.currentTarget);
  const handleClickUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleClickArticleMenu = (event) =>
    setAnchorElArticleMenu(event.currentTarget);
  const handleClickAdminMenu = (event) =>
    setAnchorElAdminMenu(event.currentTarget);

  const handleCloseNav = () => setAnchorElNav(null);
  const handleCloseLangageMenu = (lang) => {
    setAnchorElLangage(null);
    changeLanguageHandler(lang);
  };
  const handleCloseReportMenu = () => setAnchorElReportMenu(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const handleCloseArticleMenu = () => setAnchorElArticleMenu(null);
  const handleCloseAdminMenu = () => setAnchorElAdminMenu(null);

  const handleLoginAs = async () => {
    const username = prompt("Please enter a valid username:", "");
    if (username) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Login/loginAs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify({ username, password: null }),
        }
      );
      const data = await response.json();
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("username", username);
      localStorage.setItem("token", data.token);
      store.dispatch(login({ username, idSession: data.idSession }));
      window.location.reload(true);
    }
  };

  const handleLogout = () => {
    Cookies.remove("isAuthentificated");
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload(true);
  };

  const getEmployeAccessRight = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/employee/GetEmployeAccessRight/${userState.username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    );
    const data = await response.json();
    setEmploye(data);
  };

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    store.dispatch(setLang(lang));
  };

  return (
    Cookies.get("isAuthentificated") === "true" && (
      <AppBar
        position="static"
        sx={{ backgroundColor: "#FFBC0F", marginTop: 5 }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={logo} alt="Logo" />
            </Typography>

            {/* Mobile Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClickNav}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNav}
              >
                <Button
                  id="basic-button"
                  aria-controls={
                    Boolean(anchorElNav) ? "basic-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorElNav) ? "true" : undefined}
                  onClick={handleClickNav}
                >
                  {t("Memo Hebdo")}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElNav}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNav}
                  MenuListProps={{ "aria-labelledby": "basic-button" }}
                >
                  <Button
                    id="article-button"
                    aria-controls={openArticleMenu ? "article-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openArticleMenu ? "true" : undefined}
                    onClick={handleClickArticleMenu}
                  >
                    {t("Memo Hebdo")}
                  </Button>
                  <Menu
                    id="article-menu"
                    anchorEl={anchorElArticleMenu}
                    open={openArticleMenu}
                    onClose={handleCloseArticleMenu}
                    MenuListProps={{ "aria-labelledby": "article-button" }}
                  >
                    <MenuItem key="MemoHebdo" component={Link} to="/MemoHebdo">
                      {t("Articles")}
                    </MenuItem>
                    {canEditArticle && (
                      <MenuItem
                        key="EditArticle"
                        component={Link}
                        to="/EditArticle"
                      >
                        {t("WriteArticle")}
                      </MenuItem>
                    )}
                  </Menu>
                </Menu>
                {canWriteSpecialMessage && (
                  <MenuItem
                    onClick={handleCloseNav}
                    component={Link}
                    to="/SpecialMessage"
                  >
                    {t("SpecialMessage")}
                  </MenuItem>
                )}{" "}
                {canModifyEmployee && (
                  <Button
                    key="UserManagement"
                    component={Link}
                    to="/UserManagement"
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("UserManagement")}
                  </Button>
                )}
                {canModifyAPI && (
                  <>
                    <Button
                      id="basic-button"
                      aria-controls={openAdminMenu ? "admin-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openAdminMenu ? "true" : undefined}
                      onClick={handleClickAdminMenu}
                    >
                      {t("Admin")}
                    </Button>
                    <Menu
                      id="admin-menu"
                      anchorEl={anchorElAdminMenu}
                      open={openAdminMenu}
                      onClose={handleCloseAdminMenu}
                      MenuListProps={{ "aria-labelledby": "basic-button" }}
                    >
                      <MenuItem
                        key="Emplacement"
                        component={Link}
                        to="/Emplacement"
                      >
                        {t("Location")}
                      </MenuItem>
                      <MenuItem
                        key="CompaniesManagement"
                        component={Link}
                        to="/CompaniesManagement"
                      >
                        {t("CompaniesManagement")}
                      </MenuItem>
                    </Menu>
                  </>
                )}
                {canAccessReport && (
                  <>
                    <Button
                      id="basic-button"
                      aria-controls={openReport ? "report-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openReport ? "true" : undefined}
                      onClick={handleClickReportMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {t("Reports")}
                    </Button>
                    <Menu
                      id="admin-menu"
                      anchorEl={anchorElReportMenu}
                      open={openReport}
                      onClose={handleCloseReportMenu}
                      MenuListProps={{ "aria-labelledby": "basic-button" }}
                    >
                      {(canModifyAPI || isAdminReport || canAccessReport) && (
                        <MenuItem
                          key="Dashboard"
                          component={Link}
                          to="/Dashboard"
                        >
                          {t("Dashboard")}
                        </MenuItem>
                      )}
                      <MenuItem key="Reports" component={Link} to="/Reports">
                        {t("Reports")}
                      </MenuItem>
                    </Menu>
                  </>
                )}
                {(isFranchise || canModifyAPI) && (
                  /*ADD ADMIN ACCES ONLY*/ <Button
                    key="Form"
                    component={Link}
                    to="/CompetitionSurvey"
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("CompetitionSurvey")}
                  </Button>
                )}
              </Menu>
            </Box>

            {/* Desktop Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                id="article-button"
                aria-controls={openArticleMenu ? "article-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openArticleMenu ? "true" : undefined}
                onClick={handleClickArticleMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {t("Memo Hebdo")}
              </Button>
              <Menu
                id="article-menu"
                anchorEl={anchorElArticleMenu}
                open={openArticleMenu}
                onClose={handleCloseArticleMenu}
                MenuListProps={{ "aria-labelledby": "article-button" }}
              >
                <MenuItem key="MemoHebdo" component={Link} to="/MemoHebdo">
                  {t("Articles")}
                </MenuItem>
                {canEditArticle && (
                  <MenuItem
                    key="EditArticle"
                    component={Link}
                    to="/EditArticle"
                  >
                    {t("WriteArticle")}
                  </MenuItem>
                )}
              </Menu>

              {canWriteSpecialMessage && (
                <Button
                  key="SpecialMessage"
                  component={Link}
                  to="/SpecialMessage"
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {t("SpecialMessage")}
                </Button>
              )}
              {canModifyEmployee && (
                <Button
                  key="UserManagement"
                  component={Link}
                  to="/UserManagement"
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {t("UserManagement")}
                </Button>
              )}

              {canModifyAPI && (
                <>
                  <Button
                    id="admin-button"
                    aria-controls={openAdminMenu ? "admin-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAdminMenu ? "true" : undefined}
                    onClick={handleClickAdminMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("Admin")}
                  </Button>
                  <Menu
                    id="admin-menu"
                    anchorEl={anchorElAdminMenu}
                    open={openAdminMenu}
                    onClose={handleCloseAdminMenu}
                    MenuListProps={{ "aria-labelledby": "admin-button" }}
                  >
                    <MenuItem
                      key="Emplacement"
                      component={Link}
                      to="/Emplacement"
                    >
                      {t("Location")}
                    </MenuItem>
                    <MenuItem
                      key="CompaniesManagement"
                      component={Link}
                      to="/CompaniesManagement"
                    >
                      {t("CompaniesManagement")}
                    </MenuItem>
                  </Menu>

                  <Button
                    key="APIKey"
                    component={Link}
                    to="/APIKey"
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("API Key")}
                  </Button>
                  <Button
                    key="Complaint"
                    component={Link}
                    to="/Complaint"
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("Complaint")}
                  </Button>
                </>
              )}

              {canAccessReport && (
                <>
                  <Button
                    id="basic-button"
                    aria-controls={openReport ? "report-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openReport ? "true" : undefined}
                    onClick={handleClickReportMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t("Reports")}
                  </Button>
                  <Menu
                    id="admin-menu"
                    anchorEl={anchorElReportMenu}
                    open={openReport}
                    onClose={handleCloseReportMenu}
                    MenuListProps={{ "aria-labelledby": "basic-button" }}
                  >
                    {(canModifyAPI || isAdminReport || canAccessReport) && (
                      <MenuItem
                        key="Dashboard"
                        component={Link}
                        to="/Dashboard"
                      >
                        {t("Dashboard")}
                      </MenuItem>
                    )}
                    <MenuItem key="Reports" component={Link} to="/Reports">
                      {t("Reports")}
                    </MenuItem>
                  </Menu>
                </>
              )}
              {(isFranchise || canModifyAPI) && (
                /*ADD ADMIN ACCES ONLY*/ <Button
                  key="Form"
                  component={Link}
                  to="/CompetitionSurvey"
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {t("CompetitionSurvey")}
                </Button>
              )}
            </Box>

            {/* User Menu */}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={t("Settings")}>
                <IconButton onClick={handleClickUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={userState.username}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="ChangeLanguage" onClick={handleClickLangageMenu}>
                  <Typography textAlign="center">{t("Langage")}</Typography>
                </MenuItem>
                <Menu
                  id="lang-menu"
                  anchorEl={anchorElLangage}
                  open={openLang}
                  onClose={() => setAnchorElLangage(null)}
                  MenuListProps={{ "aria-labelledby": "basic-button" }}
                >
                  <MenuItem
                    key="FR"
                    onClick={() => handleCloseLangageMenu("FR")}
                  >
                    {t("FR")}
                  </MenuItem>
                  <MenuItem
                    key="EN"
                    onClick={() => handleCloseLangageMenu("EN")}
                  >
                    {t("EN")}
                  </MenuItem>
                </Menu>
                <MenuItem key="Logout" onClick={handleLogout}>
                  <Typography textAlign="center">{t("Logout")}</Typography>
                </MenuItem>
                {canModifyAPI && (
                  <MenuItem key="LoginAs" onClick={handleLoginAs}>
                    <Typography textAlign="center">{t("LoginAs")}</Typography>
                  </MenuItem>
                )}
                <MenuItem key={"Profil"} component={Link} to={"/Profil"}>
                  <Typography textAlign="center">{t("Profil")}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    )
  );
}
