import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import "../../css/EditArticle.css";
import Editor from "./Editor";
import EdditionArticleInProgress from "./EdditionArticleInProgress";
import UploadedFile from "./UploadedFile";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Login from "../Login";

export function EditArticle() {
  const [activeArticle, setActiveArticle] = useState();
  const [reloadArticle, setReloadArticle] = useState(false);
  const [time, setTime] = useState(Date.now());
  const [readOnly, setReadOnly] = useState(true);

  const userState = useSelector((state) => state.user);

  const handleSelectArticle = (e) => {
    if (e != null) {
      setActiveArticle(e);
      setReloadArticle(true);
      console.log(e);
      if (e.employeLock && e.employeLock != userState.username) {
        alert(
          `This article is locked by ${e.employeLock} and can be incomplete`
        );
      }
    } else {
      setActiveArticle();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 2000);

    return () => {
      setReloadArticle(true);
      clearInterval(interval);
    };
  }, [time]);

  return (
    <>
      {!Cookies.get("isAuthentificated") ? (
        <Login />
      ) : (
        <div className="EditArticle__Editor">
          <EdditionArticleInProgress
            selectArticle={handleSelectArticle}
            reloadArticle={reloadArticle}
            setReload={setReloadArticle}
          />

          <Editor
            activeArticle={activeArticle ? activeArticle : null}
            selectArticle={handleSelectArticle}
            setSelectedArticle={setActiveArticle}
            reloadArticle={setReloadArticle}
            readOnly={setReadOnly}
          />
          {activeArticle ? (
            <UploadedFile
              idArticle={activeArticle ? activeArticle.id : null}
              reloadArticle={reloadArticle}
              readOnly={readOnly}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}

export default EditArticle;
