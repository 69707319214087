import React, { useState, useEffect } from "react";

// ConnectionStatus component
const ConnectionStatus = ({ onStatusChange }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      onStatusChange(true);
    };
    const handleOffline = () => {
      setIsOnline(false);
      onStatusChange(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [onStatusChange]);

  return null; // No UI, just functionality
};

export default ConnectionStatus;
