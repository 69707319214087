import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Login from "../Login";
import Cookies from "js-cookie";
import QuestionsForm from "./QuestionsForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import SubmitReport from "./SubmitReport";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ConnectionStatus from "../../utils/ConnectionStatus";

import "../../css/FormRepport.css";

export function FormRepport(props) {
  const { t } = useTranslation(["Articles"], ["Common"]);
  const [typeActivities, setTypeActivities] = useState([]);
  const [typeActivity, setTypeActivity] = useState([]);
  const [typeActivityText, setTypeActivityText] = useState();
  const [questions, setQuestions] = useState([]);
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [reportsByUsername, setReportsByUsername] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [restaurants, setRestaurants] = useState([]);
  const [noEmplacement, setNoEmplacement] = useState([]);

  const [idActivite, setIdActivite] = useState();
  const [answersDisable, setAnswersDisable] = useState(true);

  const [openModalSubmit, setOpenModalSubmit] = React.useState(false);
  const [myReports, setMyReports] = useState();
  const [reponses, setReponses] = useState([]);
  const [report, setReport] = useState();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [buttonDeleteReportDisable, setButtonDeleteReportDisable] =
    useState(true);

  const handleOpen = () => setOpenModalSubmit(true);
  const handleCloseModalSubmit = () => {
    setOpenModalSubmit(false);
    GetReportsByUsername();
    setMyReports();
  };

  const userState = useSelector((state) => state.user);

  const userLang = useSelector((state) => state.user.lang);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    GetActivities();
    GetReportsByUsername();
    GetRestaurants();
  }, []);

  useEffect(() => {
    if (idActivite) {
      GetQuestions();
    }
  }, [idActivite]);

  const handleChangeTypeActivities = (event) => {
    setTypeActivity(
      typeActivities.filter(
        (activite) =>
          activite.textFr === event.target.textContent ||
          activite.textEn === event.target.textContent
      )
    );
    setTypeActivityText(event.target.textContent);
  };

  const GetActivities = async () => {
    var acivities = {
      username: userState.username,
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/Report/GetActivities/${userState.username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setTypeActivities(data);
      });
  };

  const GetQuestions = async () => {
    if (typeActivity) {
      fetch(
        `${process.env.REACT_APP_API_URL}/Report/GetQuestions/${typeActivity[0].id}`,
        {
          method: "GET",
          withCredentials: true,
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      )
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setQuestions(data);
          setAnswersDisable(false);
        });
    }
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const GetReportsByUsername = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Report/GetReportsByUsername/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setMyReports(data);
        setNoEmplacement(data.idEmplacement);
      });
  };

  const GetRestaurants = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/Localization/GetRestaurantsByUser/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let headOffice = {
          noEmplacement: 1,
          nomEmplacement: "Bureau Chef / Head Office",
        };
        data.unshift(headOffice);
        setRestaurants(data);
      });
  };

  const handleChangeRestaurant = (e) => {
    setRestaurant(e.target.textContent);
    if (e.target.textContent) {
      setNoEmplacement(
        restaurants.filter((r) => r.nomEmplacement === e.target.textContent)[0]
          .noEmplacement
      );
    }
    SaveRestaurant(e.target.textContent);
  };

  const openSubmitReport = () => {
    setOpenModalSubmit(true);
  };

  const handleStatusChange = (status) => {
    setIsOnline(status);
  };

  const handleNewReport = async () => {
    if (typeActivity.length > 0 && restaurant) {
      var newActivity = {
        id: 0,
        IdTypeActivite: typeActivity[0].id,
        DateComplete: null,
        DateEvenement: null,
        username: userState.username,
        location: restaurant,
      };

      fetch(`${process.env.REACT_APP_API_URL}/Report/NewActivity`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(newActivity),
      })
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          setAnswersDisable(false);
          setIdActivite(data.id);
          GetReportsByUsername();
          GetQuestions();
        });
    }
  };

  const handleSelectReport = (report) => {
    GetReportsByUsername();
    setReport(report);
    setQuestions(report.listeQuestions);
    setReponses(report.listeReponses);
    setTypeActivityText(report.titleRepportFr);
    if (restaurants.filter((r) => r.noEmplacement == report.idEmplacement)[0]) {
      setRestaurant(
        restaurants.filter((r) => r.noEmplacement == report.idEmplacement)[0]
          .nomEmplacement
      );
    }
    setNoEmplacement(report.idEmplacement);
    setTypeActivity(
      typeActivities.filter(
        (activite) =>
          activite.textFr === report.titleRepportFr ||
          activite.textEn === report.titleRepportEn
      )
    );
    setAnswersDisable(false);
    setButtonDeleteReportDisable(false);
    setIdActivite(report.idActivite);
  };

  const SaveRestaurant = async (location) => {
    if (idActivite) {
      var newActivity = {
        id: idActivite,
        username: userState.username,
        Location: location,
      };

      fetch(`${process.env.REACT_APP_API_URL}/Report/SaveRestaurant`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(newActivity),
      })
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          GetReportsByUsername();
        });
    }
  };

  const handleDeleteReport = async () => {
    if (idActivite) {
      var deleteReport = {
        idActivite: idActivite,
        username: userState.username,
      };

      fetch(`${process.env.REACT_APP_API_URL}/Report/DeleteRepport`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(deleteReport),
      })
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          GetReportsByUsername();
          setAnswersDisable(true);
          setIdActivite(null);
          setQuestions([]);
          setReponses([]);
          setTypeActivityText("");
          setRestaurant("");
          setButtonDeleteReportDisable(true);
        });
    }
  };

  return (
    <>
      {!Cookies.get("isAuthentificated") ? (
        <Login />
      ) : (
        <div>
          <ConnectionStatus onStatusChange={handleStatusChange} />
          {isOnline ? (
            <div className="FormRepport__Main">
              <div className="FormRepport__Treeview">
                {" "}
                <Box
                  sx={{
                    height: 270,
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: "auto",
                  }}
                >
                  <TreeView
                    aria-label="controlled"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                    multiSelect
                  >
                    <TreeItem nodeId={500} label={t("My reports")}>
                      {myReports &&
                        myReports.map((report, i) => (
                          <TreeItem
                            nodeId={50 + i++}
                            label={
                              userLang === "EN"
                                ? report.titleRepportEn +
                                  " (" +
                                  report.nomEmplacement +
                                  ")"
                                : report.titleRepportFr +
                                  " (" +
                                  report.nomEmplacement +
                                  ")"
                            }
                            onClick={(e) => handleSelectReport(report)}
                          />
                        ))}
                    </TreeItem>
                  </TreeView>
                </Box>
              </div>
              <div className="FormRepport__Question">
                <FormControl fullWidth>
                  <Autocomplete
                    id={t("Report")}
                    options={typeActivities.map((activity) =>
                      userLang == "EN" ? activity.textEn : activity.textFr
                    )}
                    onChange={(e) => handleChangeTypeActivities(e)}
                    sx={{ marginRight: "10px", marginTop: "15px" }}
                    value={typeActivityText ?? ""}
                    renderInput={(params) => (
                      <TextField {...params} label={t("Report")} />
                    )}
                  />
                </FormControl>
                <Box>
                  <FormControl fullWidth>
                    <Autocomplete
                      id={t("Location")}
                      options={restaurants.map((resto) => resto.nomEmplacement)}
                      onChange={(e) => handleChangeRestaurant(e)}
                      sx={{ marginRight: "10px", marginTop: "15px" }}
                      value={restaurant ?? ""}
                      renderInput={(params) => (
                        <TextField {...params} label={t("Location")} />
                      )}
                    />
                  </FormControl>
                </Box>
                <Button onClick={handleNewReport}>{t("New report")}</Button>
                <Button
                  onClick={handleDeleteReport}
                  disabled={buttonDeleteReportDisable}
                >
                  {t("Delete report")}
                </Button>

                {idActivite &&
                  questions &&
                  questions.map((question) => (
                    <div key={question.id}>
                      {question.enfants && question.enfants.length > 0 ? (
                        <>
                          <h4>{question.textEn}</h4>
                          <FormControl fullWidth>
                            {question.enfants.map((q) => (
                              <QuestionsForm
                                key={q.id}
                                questions={q}
                                answersEnable={answersDisable}
                                idActivite={idActivite}
                                answers={reponses}
                                getReport={GetReportsByUsername}
                                withoutChild={false}
                              />
                            ))}
                          </FormControl>
                        </>
                      ) : question.textFr && question.textEn ? (
                        <FormControl fullWidth>
                          <QuestionsForm
                            questions={question}
                            answersEnable={answersDisable}
                            idActivite={idActivite}
                            answers={reponses}
                            getReport={GetReportsByUsername}
                            withoutChild={true}
                          />
                        </FormControl>
                      ) : null}
                    </div>
                  ))}
                {idActivite && (
                  <Button variant="contained" onClick={openSubmitReport}>
                    {t("Submit")}
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div>
              <h1>You are currently offline</h1>
            </div>
          )}
          <Modal
            open={openModalSubmit}
            onClose={handleCloseModalSubmit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Submission
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <SubmitReport
                  location={restaurant}
                  idActivite={idActivite}
                  close={handleCloseModalSubmit}
                />
              </Typography>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
}

export default FormRepport;
