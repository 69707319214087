import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, {
  modules,
  QuillToolbar2,
  modules2,
  formats,
} from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import ArticleProperties from "./ArticleProperties";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

import "../../css/Editor.css";
import { useSelector } from "react-redux";

export function Editor(props) {
  const [id, setId] = useState(0);
  const [textFr, settextFr] = React.useState(null);
  const [textEn, settextEn] = React.useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [titleFR, setTitleFR] = useState("");
  const [titleEN, setTitleEN] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [endPublishedDate, setEndPublishedDate] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [idAuthor, setIdAuthor] = useState(0);
  const [author, setAuthor] = useState("");
  const [idSignataire, setIdSignataire] = useState(0);
  const [idEmployeLock, setIdEmployeLock] = useState(0);
  const [idDepartement, setIdDepartement] = useState(0);
  const [isEditting, setIsEditting] = useState(false);
  const [isLock, setIsLock] = useState(false);
  const [isNewArticle, setIsNewArticle] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openDialogUpload, setOpenDialogUpload] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [lang, setLang] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState();
  const { t, i18n } = useTranslation(["Articles"]);
  const fileInput = React.useRef();

  const userState = useSelector((state) => state.user);

  const changeHandler = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsFilePicked(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("articleId", selectedArticle.id);
    formData.append("articleLang", lang);

    // Log FormData contents for debugging
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/UploadFile`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  const handleChange = (event) => {
    if (
      selectedArticle.id &&
      (selectedArticle.employeLock == null ||
        selectedArticle.employeLock.includes(userState.username))
    ) {
      setChecked(event.target.checked);
      setReadOnly(!event.target.checked);
      props.readOnly(!event.target.checked);
      if (event.target.checked) {
        handleEditArticle();
      }
    } else {
      alert(t("ArticleLocked") + `${selectedArticle.employeLock}`);
    }
  };

  const handleClickOpenDialogUpload = () => {
    setOpenDialogUpload(true);
  };

  const handleClickOpen = () => {
    if (
      selectedArticle.idAuthor == selectedArticle.idEmployeLock ||
      props.activeArticle.employeLock == null ||
      props.activeArticle.employeLock.includes(userState.username)
    ) {
      setOpen(true);
    } else {
      alert(t("LockedDelete"));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialogUpload = () => {
    setOpenDialogUpload(false);
  };

  const handleChangeFr = async (value) => {
    await settextFr(value);
  };

  const handleChangeEn = (value) => {
    settextEn(value);
  };

  const forcedSaveArticle = async (event) => {
    event.stopPropagation();
    if (titleEN || titleFR) {
      var articleData = {
        id: id,
        titleFr: titleFR,
        titleEn: titleEN,
        textFr: textFr,
        textEn: textEn,
        idAuthor: 0,
        author: userState.username,
        idSignataire: idSignataire,
        idEmployeLock: null,
        employeLock: "",
        idCategory: idDepartement,
        creationDate: "2024-07-30T06:22:04.853Z",
        publicationDate: publishedDate,
        endPublicationDate: endPublishedDate,
        externalDepartmentId: 0,
        idEmplacement: [],
        idExternalDepartment: [],
      };

      fetch(`${process.env.REACT_APP_API_URL}/article/SaveArticle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(articleData),
      }).then((results) => {
        return results.json();
      });
    } else {
      alert("Title required !");
    }
  };

  const handleSaveArticle = async () => {
    if (titleEN || titleFR) {
      var articleData = {
        id: id,
        titleFr: titleFR,
        titleEn: titleEN,
        textFr: textFr,
        textEn: textEn,
        idAuthor: 0,
        author: userState.username,
        idSignataire: idSignataire,
        idEmployeLock: idEmployeLock || null,
        employeLock: null,
        idCategory: idDepartement,
        creationDate: "2024-07-30T06:22:04.853Z",
        publicationDate: publishedDate,
        endPublicationDate: endPublishedDate,
        externalDepartmentId: 0,
        idEmplacement: [],
        idExternalDepartment: [],
      };
      setIsEditting(false);
      setReadOnly(true);
      fetch(`${process.env.REACT_APP_API_URL}/article/SaveArticle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(articleData),
      })
        .then((results) => {
          return results.json();
        })
        .then(() => {
          setChecked(false);
          setIsLock(false);
          props.reloadArticle(true);
          setSelectedArticle();
          props.selectArticle();
          setId();
          settextFr("");
          settextEn("");
          setTitleEN("");
          setTitleFR("");
          setReadOnly(true);
          setIsEditting(false);
          setAuthor(userState.username);
          setPublishedDate("");
          setEndPublishedDate("");
        });
    } else {
      alert("Title required !");
    }
  };

  useEffect(() => {
    if (props.activeArticle) {
      setSelectedArticle(props.activeArticle);
      settextFr(props.activeArticle.textFr);
      settextEn(props.activeArticle.textEn);
      setTitleEN(props.activeArticle.titleEn);
      setTitleFR(props.activeArticle.titleFr);
      setId(props.activeArticle.id);
      setIdDepartement(props.activeArticle.idCategory);
      setPublishedDate(props.activeArticle.publishedDate);
      setEndPublishedDate(props.activeArticle.endPublishedDate);
      setCreationDate(props.activeArticle.creationDate);
      setIdAuthor(props.activeArticle.idAuthor);
      setIdSignataire(props.activeArticle.idSignataire);
      setReadOnly(true);
      setIsEditting(false);
      //setChecked(false);
      props.readOnly(readOnly);
      if (
        props.activeArticle.employeLock != null &&
        props.activeArticle.employeLock.includes(userState.username)
      ) {
        setIsLock(true);
      } else {
        setIsLock(false);
      }
    }
  }, [props.activeArticle]);

  useEffect(() => {
    if (!isNewArticle) {
      setChecked(false);
    } else {
      setReadOnly(false);
      setChecked(true);
    }
  }, [props.activeArticle]);

  const logCreateArticle = async (id) => {
    var data = {
      id: 0,
      idUser: 0,
      idObject: id,
      object: "Article",
      event: "Create",
      descriptionEvent: `Creation of article ${id} `,
      dateEvent: "2024-07-30T06:08:06.246Z",
      username: userState.username,
    };

    fetch(`${process.env.REACT_APP_API_URL}/logger/ArticleEvent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {});
  };

  const logEditArticle = async () => {
    var data = {
      id: 0,
      idUser: 0,
      idObject: id,
      object: "Article",
      event: "Edit",
      descriptionEvent: `Edition of article ${id}`,
      dateEvent: "2024-07-30T06:08:06.246Z",
      username: userState.username,
    };

    fetch(`${process.env.REACT_APP_API_URL}/logger/ArticleEvent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {});
  };

  const logDeleteArticle = async () => {
    var data = {
      id: 0,
      idUser: 0,
      idObject: id,
      object: "Article",
      event: "Delete",
      descriptionEvent: `Deletion of article ${id}`,
      dateEvent: "2024-07-30T06:08:06.246Z",
      username: userState.username,
    };

    fetch(`${process.env.REACT_APP_API_URL}/logger/ArticleEvent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {});
  };

  const handleNewArticle = async (event) => {
    setSelectedArticle();
    event.stopPropagation();
    setId();
    settextFr("");
    settextEn("");
    setTitleEN("NO TITLE");
    setTitleFR("PAS DE TITRE");
    setReadOnly(false);
    setIsEditting(true);
    setAuthor(userState.username);
    setPublishedDate("");
    setEndPublishedDate("");
    setIsLock(true);
    setChecked(true);
    setIsNewArticle(true);

    var data = {
      id: 0,
      titleFR: "PAS DE TITRE",
      titleEN: "NO TITLE",
      textFr: "",
      textEn: "",
      idAuthor: idAuthor || 0,
      author: userState.username,
      idSignataire: idSignataire,
      idEmployeLock: null,
      employeLock: "",
      idCategory: idDepartement,
      creationDate: null,
      publicationDate: null,
      endPublicationDate: null,
      externalDepartmentId: 0,
      idEmplacement: [],
      idExternalDepartment: [],
    };

    await fetch(`${process.env.REACT_APP_API_URL}/article/SaveArticle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data != "succes") {
          setId(data.id);
          setSelectedArticle(data);

          props.setSelectedArticle(data);
          logCreateArticle(data.id);
        }
      });
  };

  const handleUnlockArticle = (event) => {
    event.stopPropagation();
    setIsLock(true);
    setReadOnly(false);
    setIsEditting(true);

    var data = {
      id: id || props.activeArticle.id,
      titleFr: titleFR,
      titleEn: titleEN,
      textFr: textFr,
      textEn: textEn,
      idAuthor: idAuthor,
      author: userState.username,
      idSignataire: idSignataire,
      idEmployeLock: null,
      employeLock: userState.username,
      idCategory: idDepartement,
      creationDate: creationDate,
      publicationDate: publishedDate,
      endPublicationDate: endPublishedDate,
      externalDepartmentId: 0,
      idEmplacement: [],
      idExternalDepartment: [],
    };

    fetch(`${process.env.REACT_APP_API_URL}/article/UnlockArticle`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then(async (data) => {
        logEditArticle();
      });
  };

  const handleEditArticle = async () => {
    if (
      props.activeArticle.employeLock == null ||
      props.activeArticle.employeLock.includes(userState.username)
    ) {
      setReadOnly(false);
      setIsEditting(true);
      setAuthor(userState.username);
      setIsLock(true);
      setIsNewArticle(false);

      var data = {
        id: id,
        titleFr: titleFR,
        titleEn: titleEN,
        textFr: textFr,
        textEn: textEn,
        idAuthor: idAuthor,
        author: userState.username,
        idSignataire: idSignataire,
        idEmployeLock: 0,
        employeLock: userState.username,
        idCategory: idDepartement,
        creationDate: creationDate,
        publicationDate: publishedDate,
        endPublicationDate: endPublishedDate,
        externalDepartmentId: 0,
        idEmplacement: [],
        idExternalDepartment: [],
      };

      await fetch(`${process.env.REACT_APP_API_URL}/article/SaveArticle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((results) => {
          return results.json();
        })
        .then(async (data) => {
          logEditArticle();
        });
    } else {
      alert(t("ArticleLocked") + props.activeArticle.employeLock);
    }
  };

  const handleDeleteArticle = async () => {
    try {
      // Set initial states
      setIsNewArticle(false);

      // Check if the article is locked or can be deleted by the user
      if (
        !selectedArticle.employeLock ||
        selectedArticle.employeLock.includes(userState.username)
      ) {
        // Prepare the data for deletion
        const articleData = {
          id: selectedArticle.id,
          Author: userState.username,
          publishedDate: selectedArticle.publishedDate,
        };

        // Reset form states
        resetFormFields();

        // Make the API call to delete the article
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/article/DeleteArticle`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
            body: JSON.stringify(articleData),
          }
        );

        // Check if the response body is not empty
        if (response.ok) {
          // Only parse JSON if the response body is not empty
          const responseText = await response.text();
          const data = responseText ? JSON.parse(responseText) : null;

          handleClose();
          logDeleteArticle();
          props.reloadArticle(true);
          resetSelectedArticle();
          setChecked(false);
          setIsLock(false);
          props.selectArticle();
        } else {
          console.error("Error response from server:", response.statusText);
          alert(t("ErrorDeleteArticle"));
        }
      } else {
        alert(t("ErrorDeleteArticle"));
      }
    } catch (error) {
      console.error("An error occurred while deleting the article:", error);
      alert(t("ErrorDeleteArticle"));
    }
  };

  const resetFormFields = () => {
    setId(null);
    settextFr("");
    settextEn("");
    setTitleEN("");
    setTitleFR("");
    setReadOnly(true);
    setIsEditting(false);
    setAuthor(userState.username);
    setPublishedDate("");
    setEndPublishedDate("");
  };

  const resetSelectedArticle = () => {
    setSelectedArticle(null);
  };

  const handleKeyPressed = async (event) => {
    event.stopPropagation();
    setIsNewArticle(false);

    var data = {
      id: selectedArticle.id,
      titleFr: titleFR,
      titleEn: titleEN,
      textFr: textFr,
      textEn: textEn,
      idAuthor: idAuthor,
      author: author,
      idSignataire: idSignataire,
      idEmployeLock: null,
      employeLock: author,
      idCategory: idDepartement,
      creationDate: "2024-07-30T06:22:04.853Z",
      publicationDate: publishedDate,
      endPublicationDate: endPublishedDate,
      externalDepartmentId: 0,
      idEmplacement: [],
      idExternalDepartment: [],
    };

    await fetch(`${process.env.REACT_APP_API_URL}/article/SaveArticle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if (data != "succes") {
          setId(data);
        }
      });
  };

  const LangFR = () => {
    setLang("FR");
    fileInput.current.click();
    handleCloseDialogUpload();
  };

  const LangEN = () => {
    setLang("EN");
    fileInput.current.click();
    handleCloseDialogUpload();
  };

  return (
    <>
      <div className="Editor__Main">
        <div className="Editor__ButtonBar">
          <Button onClick={(e) => handleNewArticle(e)}>
            {t("NewArticle")}
          </Button>
          <Button
            component="label"
            disabled={readOnly}
            onClick={handleClickOpenDialogUpload}
          >
            {t("UploadFile")}
          </Button>
          <Dialog
            open={openDialogUpload}
            onClose={handleCloseDialogUpload}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Langage ?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("WhichLanguage")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={LangFR}>
                FR
              </Button>
              <Button onClick={LangEN}>EN</Button>
              <Button onClick={handleCloseDialogUpload}>{t("Close")}</Button>
            </DialogActions>
          </Dialog>
          <Button onClick={(e) => handleUnlockArticle(e)} disabled={!isLock}>
            {t("Unlock")}
          </Button>
          <Button
            onClick={handleClickOpen}
            disabled={selectedArticle ? false : true}
          >
            {t("Delete")}
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("DeleteConfirmationTitle")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("DeleteConfirmation")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteArticle} autoFocus>
                {t("Yes")}
              </Button>
              <Button onClick={handleClose}>{t("No")}</Button>
            </DialogActions>
          </Dialog>

          <ArticleProperties
            activeArticleProperties={selectedArticle}
            readOnly={readOnly}
            setReadOnly={setIsEditting}
            setPublicationDate={setPublishedDate}
            setEndPublicationDate={setEndPublishedDate}
            saveArticle={forcedSaveArticle}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className="Editor__ButtonBar__toggleButton"
          >
            <Typography>{t("ReadOnly")}</Typography>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography>{t("EditMode")}</Typography>
          </Stack>
        </div>
        <div className="Editor__Main_FR">
          {selectedArticle ? (
            <>
              <TextField
                margin="normal"
                fullWidth
                id="outlined-titleFr"
                label="Titre"
                name="titleFr"
                disabled={readOnly}
                onChange={(e) => setTitleFR(e.currentTarget.value)}
                value={titleFR}
                onKeyUp={(e) => handleKeyPressed(e)}
              />

              <EditorToolbar handleSave={(e) => handleKeyPressed(e)} />
              <ReactQuill
                theme="snow"
                value={textFr}
                onChange={handleChangeFr}
                placeholder={"article en francais..."}
                modules={modules}
                formats={formats}
                readOnly={readOnly}
                onKeyUp={(e) => handleKeyPressed(e)}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="Editor__Main_EN">
          {selectedArticle ? (
            <>
              <TextField
                margin="normal"
                fullWidth
                id="titleEN"
                label="Title"
                name="titleEN"
                onChange={(e) => setTitleEN(e.currentTarget.value)}
                disabled={readOnly}
                value={titleEN}
                onKeyUp={(e) => handleKeyPressed(e)}
              />
              <QuillToolbar2 handleSave={(e) => handleKeyPressed(e)} />
              <ReactQuill
                theme="snow"
                value={textEn}
                onChange={handleChangeEn}
                placeholder={"article in english..."}
                formats={formats}
                readOnly={readOnly}
                modules={modules2}
                onKeyUp={(e) => handleKeyPressed(e)}
              />
              <input
                ref={fileInput}
                type="file"
                style={{ opacity: "0" }}
                onChange={(e) => changeHandler(e)}
                onClick={(e) => (e.target.value = "")}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Editor;
