export const AccessRight = {
  PLAINTE_ADMIN: 1,
  PLAINTE_CONSEILLER: 2,
  SYSTEM_ADMIN: 3,
  INTRANET_FORMATION: 4,
  INTRANET_OPERATION: 5,
  INTRANET_DEVELOPMENT: 6,
  INTRANET_ADMIN_NOUVELLES: 7,
  PLAINTE_EMAILAUTO: 8,
  WINFORM_ACCES: 9,
  INTRANET_MARKETING: 10,
  ADO_ADMIN: 11,
  CASSEROLES_ADMIN: 12,
  ADMIN_FINANCE: 13,
  DECLARATION: 14,
  RAPPORTS_OPERATION: 15,
  FINANCE: 16,
  INTRANET_IMPRESSION: 17,
  DEL_DATA_FINANCIER: 18,
  SUIVI_ACTIVITE_FINANCE: 19,
  EXT_ADMIN_RECETTE: 20,
  SUIVI_ACTIVITE_ADMIN: 21,
  MEMOHEBDO_PUBLIER: 22,
  MEMOHEBDO_EDITEUR: 23,
  EXT_ADMIN_FICHIER: 24,
  EXT_ADMIN_CAPSULE: 25,
  ACTIVITE_ADMIN: 26,
  STORE_ADMIN: 27,
  INTRANET_NIVEAU2: 28,
  DEVELOPPEMENT_AFFAIRE: 29,
  INTRANET_FINANCE: 30,
  EXT_ADMIN_FORUM: 31,
  DECLARATION_REPORT: 32,
  Creation_Formulaire: 33,
  DROIT_DISPO: 34,
  MODIFIER_BOTTIN: 35,
  SMS_USER: 36,
  GROUPING_ADMIN: 37,
  WEBSITE_EDITOR: 38,
  UserManagement: 39,
  REPORT_EDDITOR: 42,
  REPORT_ADMIN: 43,
};
