import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";

function SubmitReport(props) {
  const { t } = useTranslation(["Articles"], ["Common"]);
  const [notification, setNotification] = useState("None");
  const [sendTo, setSendTo] = useState("None");

  const userState = useSelector((state) => state.user);

  const handlesubmitReport = async () => {
    var activity = {
      IdActivite: props.idActivite,
      nomEmplacement: props.location,
      notification: notification,
      sendTo: sendTo,
      username: userState.username,
    };

    fetch(`${process.env.REACT_APP_API_URL}/Report/SubmitReport`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(activity),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        props.close();
      });
  };

  return (
    <>
      <div>
        <FormControl>
          {/*<FormLabel id="demo-row-radio-buttons-group-label">Send to</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onClick={(e) => setSendTo(e.target.value)}
            defaultValue="None"
          >
            <FormControlLabel
              value="exploitant"
              control={<Radio />}
              label="exploitant"
            />
            <FormControlLabel value="None" control={<Radio />} label="None" />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Notification
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onClick={(e) => setNotification(e.target.value)}
            defaultValue="None"
          >
            <FormControlLabel value="24" control={<Radio />} label="24h" />
            <FormControlLabel value="48" control={<Radio />} label="48h" />
            <FormControlLabel value="72" control={<Radio />} label="72h" />
            <FormControlLabel value="None" control={<Radio />} label="None" />
  </RadioGroup>*/}
        </FormControl>
      </div>
      <Button variant="contained" onClick={handlesubmitReport}>
        {t("Save")}
      </Button>
    </>
  );
}

export default SubmitReport;
